import React from "react";
import { Card, Select, message, DatePicker } from "antd";

import { Row, Col, FormGroup, Spinner } from 'reactstrap';

import { services } from '../../../services/services';

import { authenticationService } from "../../../services/authentication-service";
//import { use_logiciel } from '../../../env'
//import { last } from "lodash";
import { numberWithCommas } from '../../../helpers/index';



const currentUser = authenticationService.currentUserValue;

const moment = require('moment')
//const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const dateFormat = 'DD/MM/YYYY';
//const { RangePicker } = DatePicker;

//Récuppérer le premier et le dernier jour du mois 
const dateTime = new Date(), y = dateTime.getFullYear(), m = dateTime.getMonth();
//const firstDay = new Date(y, m, 1);
const lastDay = new Date(y, m + 1, 0);

class Operation extends React.Component {
    state = {
        isModalVisible: false,
        isAddModalVisible: false,
        isUpdateModalVisible: false,

        loading: false,
        operations: [],

        agences: [],

        startDate: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        endDate: lastDay,

        codAgenceChange: '',

        waitingEndMsg: '',
        isLoading: true,
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }



    componentDidMount() {

        let requete = window.location.search.substring(1);
        //console.log('SEARCH', requete, window.location.search)

        let startDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')

        if (requete) {
            this.setState({ startDate: moment(new Date(requete), 'YYYY-MM-DD').format('DD/MM/YYYY') })

            startDate = requete

        }
        console.log('REQUETE', requete, startDate, this.state)

        // const startDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')
        //const endDate = moment(new Date(lastDay), 'DD/MM/YYYY').format('YYYY-MM-DD')

        services.getOperations('', startDate)
            .then(operations => {
                console.log('operations', operations)
                this.setState({ operations })
                setTimeout(() => {

                    // console.log("real operations", factures)
                    if (!operations || !Array.isArray(operations)
                        || !operations.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucune Journée actuellement !" })
                    }
                }, 500)
            })
            .catch(() => {
                message.error('Une erreur est survenue lors du chargement des données, Veuillez réessayer plus tard', 10)
                this.setState({ isLoading: false, waitingEndMsg: "Une erreur est survenue lors du chargement des données, Veuillez réessayer plus tard!" })

            });
        services.getAllAgences()
            .then(agences => {
                //  //console.log('agences', agences)
                this.setState({ agences })
            })

    }

    onDateChange = (date, dateString) => {
        /// console.log("DATE", date, dateString, dateString[0]);
        this.setState({ isLoading: true, factures: [] })

        const startDate = moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD')
        //const endDate = moment(dateString[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
        //  //console.log("DATE CHANGE PERIODE", dateString[0], dateString[1], this.state.dateEnCours, this.state.codAgenceChange, startDate, endDate)

        services.getOperations(this.state.codAgenceChange, startDate)
            .then(operations => {
                //console.log('operations', operations)
                this.setState({ operations })
                setTimeout(() => {

                    // console.log("real operations", factures)
                    if (!operations || !Array.isArray(operations)
                        || !operations.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucune Operation actuellement !" })
                    }
                }, 500)
            })
            .catch(() => {
                message.error('Une erreur est survenue lors du chargement des données, Veuillez réessayer plus tard', 10)
                this.setState({ isLoading: false })

            });

        this.setState({ startDate: dateString[0], endDate: dateString[1] })

    }

    handleChangeAgence = (value) => {

        const startDate = moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        ///const endDate = moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        this.setState({ codAgenceChange: value })
        this.setState({ isLoading: true, waitingEndMsg: '', operations: [] })
        //  //console.log("AGENCE CHANGE", this.state.dateEnCours,value, startDate, endDate)

        services.getOperations(value, startDate)
            .then(operations => {
                console.log('operations', operations)
                this.setState({ operations })
                setTimeout(() => {

                    // console.log("real operations", factures)
                    if (!operations || !Array.isArray(operations)
                        || !operations.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucune Opération actuellement pour cette Agence !" })
                    }
                }, 500)
            })
            .catch(() => {
                message.error('Une erreur est survenue lors du chargement des données, Veuillez réessayer plus tard', 10)
                this.setState({ isLoading: false })

            });
    }



    handleBlur = () => {
        //  console.log('blur');
    }

    handleFocus = () => {
        // console.log('focus');
    }




    render() {
        const {

            //loading,

            operations,

            codAgenceChange,
            agences,
            //endDate,
            startDate,

            isLoading,
            waitingEndMsg,

        } = this.state

        const Option = Select.Option;


        return (
            <div>
                <Card title="Visualisation des Opérations de la Caisse">
                    <Row >
                        <Col md={3} style={{ display: 'flex', alignItems: 'baseline' }}>
                            {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                        </Col>

                        <Col md={9} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline' }}>

                            <FormGroup className="mr-3">

                                <DatePicker className="gx-mb-3 gx-w-100"
                                    defaultValue={moment(startDate, dateFormat)}
                                    format={dateFormat}
                                    onChange={this.onDateChange}

                                />

                                {/* <RangePicker className="gx-w-100"
                                    defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                    format={dateFormat}
                                    onChange={this.onDateChange}
                                    ranges={{
                                        "Aujourd'hui": [moment(), moment()],
                                        'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                        'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                        'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                    }}
                                />*/}
                                <h6 className="mr-3" style={{ color: "black", }}><span>Filtrer par Date</span></h6>
                            </FormGroup>


                            {(currentUser && currentUser.agence && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") &&
                                <FormGroup className="mr-4">
                                    <Select
                                        value={codAgenceChange}
                                        showSearch
                                        //style={{width: 200}}
                                        style={{ width: '120%' }}
                                        placeholder="Filtrer par Agence"
                                        optionFilterProp="children"
                                        onChange={this.handleChangeAgence}
                                        onFocus={this.handleFocus}
                                        onBlur={this.handleBlur}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        <Option value=''>TOUTES</Option>
                                        {
                                            agences && agences.length ?
                                                agences.map((agence, k) => {
                                                    return <Option key={k} value={agence.codAgence}>{agence.nomAgence}</Option>
                                                }) : null
                                        }

                                    </Select>
                                    <h6 className="mr-3 mt-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                </FormGroup>}

                            {/*use_logiciel === 'NO' &&
                                <button style={{ marginLeft: 35 }}
                                    className="btn bg-primary text-light rounded"
                                    onClick={this.handleAddOpen}>
                                    NOUVEAU <i className="icon icon-add" />
                                </button>
                                    */}
                        </Col>
                    </Row>


                </Card>


                <Row>
                    {/*<Col lg={3} md={3} sm={12} xs={12}>
                        <Card className="gx-card" title="Liste des Caisses en Activité">
                            <div className="App gx-border-bottom gx-pb-3 gx-mb-3">

                                {
                                    operations &&
                                        Array.isArray(operations) &&
                                        operations.length > 0 ?

                                        ""
                                        :
                                        isLoading ?
                                            <div style={{ justifyContent: 'center' }}>

                                                <Row style={{ justifyContent: 'center' }}>
                                                    <Col style={{ justifyContent: 'center' }}>
                                                        <Spinner type="grow" color="primary" />
                                                        <Spinner type="grow" color="secondary" />
                                                        <Spinner type="grow" color="success" />
                                                        <Spinner type="grow" color="danger" />
                                                        <Spinner type="grow" color="warning" />
                                                        <Spinner type="grow" color="info" />
                                                        <Spinner type="grow" color="light" />
                                                        <Spinner type="grow" color="dark" />
                                                    </Col>
                                                </Row> <br />
                                            </div>
                                            : null
                                }



                            </div>


                        </Card>
                            </Col> */}
                    <Col lg={12} md={12} sm={12} xs={12} >
                        <Card className="gx-card" title="Liste des Opérations de caisse de la journée">
                            {
                                !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                            }
                            <div className="App gx-border-bottom gx-pb-3 gx-mb-3">
                                {
                                    operations &&
                                        Array.isArray(operations) &&
                                        operations.length > 0 ?
                                        <div className="card-body">
                                            <div className="table-responsive table-card">
                                                <table className="table table-bordered align-middle table-nowrap table-striped-columns mb-0">
                                                    <thead >
                                                        <tr >

                                                            <td rowSpan="2" style={{ width: "550px", fontSize: '19px', fontWeight: 'bold' }}>Désignations</td>
                                                            <td rowSpan="2" style={{ width: "200px", fontSize: '19px' }} className="fw-medium bg-success text-white">Entrée</td>
                                                            <td rowSpan="2" style={{ width: "200px", fontSize: '19px' }} className="fw-medium bg-danger text-white">Sortie</td>
                                                            <td rowSpan="2" style={{ width: "100px", fontSize: '19px', fontWeight: 'bold' }}>Type</td>
                                                            <td rowSpan="2" style={{ width: "100px", fontSize: '19px', fontWeight: 'bold' }}>Heure</td>
                                                        </tr>


                                                    </thead>
                                                    <tbody>

                                                        {operations && operations.length ?
                                                            operations.map((operation, k) => {
                                                                ///NOM AGENCE
                                                                return (<>
                                                                    <tr key={k}>

                                                                        <td colSpan="5" className="fw-medium bg-info" style={{ color: 'white', fontSize: '22px', fontWeight: 'bold' }}>AGENCE {operation.nomAgence}</td>
                                                                    </tr>

                                                                    {
                                                                        operation && operation.userInfo.length ?
                                                                            operation.userInfo.map((user, y) => {
                                                                                /////CAISSE  ET NOM USER
                                                                                return (<>
                                                                                    <tr key={y}>

                                                                                        <td colSpan="5" className="fw-medium bg-secondary text-white">{`Caisse: [${user && user.userDetails.caisseID}] => ${user && user.userDetails.nomAgent}  ${user && user.userDetails.prenomAgent} `}</td>

                                                                                    </tr>


                                                                                    {
                                                                                        user && user.operations.length ?
                                                                                            user.operations.map((operation, z) => {
                                                                                                ////OPERATION USER
                                                                                                return (<>


                                                                                                    <tr key={z}>

                                                                                                        <td > {operation.libOperation}</td>

                                                                                                        <td className="fw-medium  ">{operation.codTypOperation === 'ENTREE' ? numberWithCommas(operation.montantOperation) : ''}</td>
                                                                                                        <td className="fw-medium  ">{operation.codTypOperation === 'SORTIE' ? numberWithCommas(operation.montantOperation) : ""}</td>
                                                                                                        <td >{operation.codTypOperation}</td>
                                                                                                        <td >{operation.heure}</td>
                                                                                                    </tr>

                                                                                                </>

                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            null
                                                                                    }

                                                                                    { /// FIN DE CHAQUE TR DE USER & CAISSE POUR TOTAL USER 
                                                                                        //////TOTAL USER CAISE
                                                                                        <tr >

                                                                                            <td className="fw-medium bg-warning"> {`Caisse: ${user && user.userDetails.caisseID}, Solde: ${numberWithCommas((user ? user.totalUserEntree : 0) - (user ? user.totalUserSortie : 0))} F CFA`} </td>

                                                                                            <td className="fw-medium bg-warning">{numberWithCommas(user ? user.totalUserEntree : 0)}</td>
                                                                                            <td className="fw-medium bg-warning">{numberWithCommas(user ? user.totalUserSortie : 0)}</td>
                                                                                            <td className="fw-medium bg-warning"></td>
                                                                                            <td className="fw-medium bg-warning"></td>
                                                                                        </tr>

                                                                                    }


                                                                                </>

                                                                                )
                                                                            })
                                                                            :
                                                                            null
                                                                    }

                                                                    { /// FIN DE CHAQUE TR DE AGENCE TOTAL AGENCE 
                                                                        //////////////// TOTAL AGENCE
                                                                        <tr >

                                                                            <td className="fw-medium bg-danger" style={{ color: 'white', }}>
                                                                                {`Total Caisse AGENCE:  `} {numberWithCommas((operation.totalAgenceEntree || 0) - (operation.totalAgenceSortie || 0))} F CFA

                                                                            </td>

                                                                            <td className="fw-medium bg-danger text-white">
                                                                                {numberWithCommas(operation.totalAgenceEntree || 0)}
                                                                            </td>
                                                                            <td className="fw-medium bg-danger text-white">
                                                                                {numberWithCommas(operation.totalAgenceSortie || 0)}
                                                                            </td>
                                                                            <td className="fw-medium bg-danger"></td>
                                                                            <td className="fw-medium bg-danger"></td>
                                                                        </tr>

                                                                    }

                                                                    {//// METTRE UNE SEPARATION APRES CHAQUE AGENCE
                                                                        <tr>
                                                                            <td></td>
                                                                        </tr>

                                                                    }

                                                                </>
                                                                );

                                                            })
                                                            :
                                                            null
                                                        }
                                                        { /// FIN TOTAL TOUTES AGENCES
                                                            ///////////TOTAL ALL AGENCES
                                                            <tr >

                                                                <td className="fw-medium bg-success" style={{ color: 'white', }}>
                                                                    {`Total Caisse TOUTES AGENCE:  `}

                                                                    {
                                                                        numberWithCommas(
                                                                            (operations && operations.length ?
                                                                                operations.reduce(function (prev, cur) {
                                                                                    return prev + parseFloat(cur.totalAgenceEntree)
                                                                                }, 0) : 0)
                                                                            -
                                                                            (operations && operations.length ?
                                                                                operations.reduce(function (prev, cur) {
                                                                                    return prev + parseFloat(cur.totalAgenceSortie)
                                                                                }, 0) : 0)
                                                                        )

                                                                    } F CFA
                                                                </td>

                                                                <td className="fw-medium bg-success text-white">
                                                                    {
                                                                        numberWithCommas(operations && operations.length ?
                                                                            operations.reduce(function (prev, cur) {
                                                                                return prev + parseFloat(cur.totalAgenceEntree)
                                                                            }, 0) : 0)
                                                                    }
                                                                </td>
                                                                <td className="fw-medium bg-success text-white">
                                                                    {numberWithCommas(operations && operations.length ?
                                                                        operations.reduce(function (prev, cur) {
                                                                            return prev + parseFloat(cur.totalAgenceSortie)
                                                                        }, 0) : 0)

                                                                    }
                                                                </td>
                                                                <td className="fw-medium bg-success"></td>
                                                                <td className="fw-medium bg-success"></td>
                                                            </tr>

                                                        }

                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        :
                                        isLoading ?
                                            <div style={{ justifyContent: 'center' }}>

                                                <Row style={{ justifyContent: 'center' }}>
                                                    <Col style={{ justifyContent: 'center' }}>
                                                        <Spinner type="grow" color="primary" />
                                                        <Spinner type="grow" color="secondary" />
                                                        <Spinner type="grow" color="success" />
                                                        <Spinner type="grow" color="danger" />
                                                        <Spinner type="grow" color="warning" />
                                                        <Spinner type="grow" color="info" />
                                                        <Spinner type="grow" color="light" />
                                                        <Spinner type="grow" color="dark" />
                                                    </Col>
                                                </Row> <br />
                                            </div>
                                            : null
                                }

                            </div>
                        </Card>
                    </Col>

                </Row>



            </div>
        )
    }
}


export default Operation;
