import React from "react";
import { Card, Input,  Button, Modal, Select, message, Popconfirm } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, Form, FormGroup, Label, Spinner } from 'reactstrap';
import Media from 'react-media';


import { services } from '../../../services/services';

import { authenticationService } from "../../../services/authentication-service";
import { use_logiciel } from '../../../env'


const currentUser = authenticationService.currentUserValue;


class Articles extends React.Component {
  state = {
    isModalVisible: false,
    isAddModalVisible: false,
    isUpdateModalVisible: false,
    isAddCategorieModalVisible: false,
    loading: false,
    articles: [],
    categories: [],
    tva: [],
    usuel: "1",
    codTyp: "1",
    designation: '',
    codeCat: '',
    codInterne: '',
    puVente: '',
    codTvaVente: '',
    idemBal: 1,

    reference: '',

    qteAlert: 0,
    stockMin: 0,
    taxeSpecifique: '',
    descriptionTs: '',
    codBarre: '',
    puAchat: '',
    especeUnite: '',
    prixDetaillant: '',
    prixGrossiste: '',

    waitingEndMsg: '',
    isLoading: true,
  }


  openCategorieAdd = () => {
    this.setState({ isAddCategorieModalVisible: !this.state.isAddCategorieModalVisible })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleAddCategorie = () => {
    const {
      //codCat,
      libCat,

    } = this.state


    if (libCat === '') {
      message.warning('Veuillez préciser la Raison sociale', 5)
      return;
    }
    /* if (codCat === '') {
       message.warning('Veuillez préciser le code de la Catégorie', 5)
       return;
     }*/
     this.setState({ loading: true })
    let req = {
      //codCat,
      libCat: libCat.toUpperCase(),

    }
    services.addCategorie(req)
      .then(result => {
       // console.log(result)
        this.setState({
          codCat: '',
          loading: false,
          libCat: '',

          isAddCategorieModalVisible: !this.state.isAddCategorieModalVisible,

        })
        message.success('Categorie ajouter avec succès', 10)

        services.getCategories()
          .then(categories => {
            this.setState({ categories })
          })

      })

      .catch((err) => {
        this.setState({ loading: false })
        message.error(err, 10)
      });
  }

  componentDidMount() {
    services.getArticles()
      .then(articles => {
       // console.log('articles', articles)
        this.setState({ articles })
        setTimeout(() => {

          // console.log("real articles", factures)
          if (!articles || !Array.isArray(articles)
            || !articles.length > 0) {
            this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucun Article actuellement !" })
          }
        }, 4000)
      })

    services.getCategories()
      .then(categories => {
        //console.log('Roles', roles)
        this.setState({ categories })
      })

    services.getTva()
      .then(tva => {
        this.setState({ tva })
      })

  }
  handleAddOpen = () => {
    this.setState({
      isAddModalVisible: !this.state.isAddModalVisible,
      codPro: '',
      reference: '',
      designation: '',
      codeCat: '',
      codTyp: '1',
      idemBal: '',
      codTvaVente: '',
      taxeSpecifique: '',
      descriptionTs: '',
      codBarre: '',
      puAchat: '',
      especeUnite: '',
      usuel: '1',
      codInterne: '',
      puVente: '',
      prixDetaillant: '',
      prixGrossiste: '',
      loading: false,
      qteAlert: 0,
      stockMin: 0,

    });
  };

  handleCancelAdd = () => {
    this.setState({
      isAddModalVisible: !this.state.isAddModalVisible,
      codPro: '',
      reference: '',
      designation: '',
      qteAlert: 0,
      stockMin: 0,
      codeCat: '',
      codTyp: '',
      idemBal: '',
      codTvaVente: '',
      taxeSpecifique: '',
      descriptionTs: '',
      codBarre: '',
      puAchat: '',
      especeUnite: '',
      usuel: '',
      codInterne: '',
      puVente: '',
      prixDetaillant: '',
      prixGrossiste: '',
    })
  }

  handleAddArticle = () => {
    const {
      //codPro,
      reference,
      designation,
      qteAlert,
      stockMin,
      codeCat,
      codTyp,
      idemBal,
      codTvaVente,
      taxeSpecifique,
      descriptionTs,
      codBarre,
      puAchat,
      especeUnite,
      usuel,
      codInterne,
      puVente,
      prixDetaillant,
      prixGrossiste,
    } = this.state


    if (designation === '') {
      message.warning('Veuillez préciser la Désignation', 5)
      return;
    }
    if (stockMin === '') {
      message.warning('Veuillez préciser le Stock Minimum', 5)
      return;
    }
    if (qteAlert === '') {
      message.warning('Veuillez préciser la Quantité Alerte', 5)
      return;
    }
    /*if (codInterne === '' || codInterne === null) {
      message.warning('Veuillez préciser la Référence', 5)
      return;
    }*/
    if (codeCat === '' || codeCat === null || codeCat === undefined) {
      message.warning('Veuillez sélectionner la Catégorie', 5)
      return;
    }

    if (codTvaVente === '' || codTvaVente === null || codTvaVente === undefined) {
      message.warning('Veuillez sélectionner la Tva', 5)
      return;
    }

    if (puVente === '' || puVente === null || puVente === undefined) {
      message.warning('Veuillez préciser le prix de vente', 5)
      return;
    }

    if (puAchat === '' || puAchat === null || puAchat === undefined) {
      message.warning('Veuillez préciser le prix de vente', 5)
      return;
    }

    if (parseFloat(stockMin) > parseFloat(qteAlert)) {
      message.warning('La quantité Alerte ne peut être inférieur au Stock minimum', 5)
      return;
    }

    if (parseFloat(puAchat) > parseFloat(puVente)) {
      message.warning('Le PU Achat ne peut être supérieur au PU Vente', 5)
      return;
    }

    this.setState({ loading: true })

    let req = {
      // codPro,
      reference: reference,
      designation: designation.toUpperCase(),
      qteAlert,
      stockMin,
      codeCat,
      codTyp,
      idemBal,
      codTvaVente,
      tauxTs: taxeSpecifique,
      dtsTs: descriptionTs,
      codBarre,
      puAchat,
      especeUnite,
      usuel,
      codInterne: codInterne.toUpperCase(),
      puVente,
      prixDetaillant,
      prixGrossiste,
      //raisonSociale: raisonSociale.toUpperCase(),

    }
    services.addArticle(req)
      .then(result => {
       // console.log(result)
        this.setState({
          codPro: '',
          reference: '',
          designation: '',
          qteAlert: 0,
          stockMin: 0,
          codeCat: '',
          codTyp: '',
          idemBal: '',
          codTvaVente: '',
          taxeSpecifique: '',
          descriptionTs: '',
          codBarre: '',
          puAchat: '',
          especeUnite: '',
          usuel: '',
          codInterne: '',
          puVente: '',
          prixDetaillant: '',
          prixGrossiste: '',
          isAddModalVisible: !this.state.isAddModalVisible,
          loading: false,

        })
        message.success('Article ajouter avec succès', 10)

        services.getArticles()
          .then(articles => {
            this.setState({ articles })
          })

      })

      .catch((err) => {
        message.error(err, 10)
      });
  }

  handleUpdateArticle = () => {
    const {
      //idArticle,
      codPro,
      reference,
      designation,
      qteAlert,
      stockMin,
      codeCat,
      codTyp,
      //idemBal,
      codTvaVente,
      taxeSpecifique,
      descriptionTs,
      codBarre,
      puAchat,
      especeUnite,
      usuel,
      codInterne,
      puVente,
      prixDetaillant,
      prixGrossiste,
    } = this.state

   // console.log('PUVENTE', puVente)
    if (designation === '') {
      message.warning('Veuillez préciser la Désignation', 5)
      return;
    }

    /*if (codInterne === '' || codInterne === null) {
      message.warning('Veuillez préciser la Référence', 5)
      return;
    }*/

    if (codeCat === '' || codeCat === null || codeCat === undefined) {
      message.warning('Veuillez sélectionner la Catégorie', 5)
      return;
    }

    if (parseFloat(stockMin) > parseFloat(qteAlert)) {
      message.warning('La quantité Alerte ne peut être inférieur au Stock minimum', 5)
      return;
    }

    if (parseFloat(puAchat) > parseFloat(puVente)) {
      message.warning('Le PU Achat ne peut être supérieur au PU Vente', 5)
      return;
    }

    let req = {
      //idArticle,
      codPro,
      reference: reference,
      designation: designation.toUpperCase(),
      qteAlert,
      stockMin,
      codeCat,
      codTyp,
      idemBal: 1,
      codTvaVente,
      tauxTs: taxeSpecifique,
      dtsTs: descriptionTs,
      codBarre,
      puAchat,
      especeUnite,
      usuel,
      codInterne: codInterne.toUpperCase(),
      puVente: puVente,
      prixDetaillant,
      prixGrossiste,
      //raisonSociale: raisonSociale.toUpperCase(),

    }
    services.updateArticle(codPro, req)
      .then(result => {
       // console.log(result)
        this.setState({
          idArticle: '',
          codPro: '',
          reference: '',
          designation: '',
          qteAlert: 0,
          stockMin: 0,
          codeCat: '',
          codTyp: '',
          idemBal: '',
          codTvaVente: '',
          taxeSpecifique: '',
          descriptionTs: '',
          codBarre: '',
          puAchat: '',
          especeUnite: '',
          usuel: '',
          codInterne: '',
          puVente: '',
          prixDetaillant: '',
          prixGrossiste: '',
          isUpdateModalVisible: !this.state.isUpdateModalVisible,

        })
        message.success('Article modifier avec succès', 10)

        services.getArticles()
          .then(articles => {
            this.setState({ articles })
          })

      })

      .catch(() => {
        message.error('Une erreur est survenue lors de la modification, Veuillez réessayer plus tard', 10)
      });
  }

  handleDelete = (row) => {
    this.setState({
      codPro: row.codPro,

      designation: row.designation,
    })

  }

  handleDeleteArticle = () => {

    const { codPro } = this.state

    services.deleteArticle(codPro)
      .then(del => {
        //console.log('delete user', del)
        message.success('Article supprimer avec succès', 10)
        services.getArticles()
          .then(articles => {
            this.setState({ articles })
          })

      })
      .catch((err) => {
        //console.log(err)
        message.error(err, 10)
      });

  }

  handleUpdateOpen = (row) => {
    this.setState({
      isUpdateModalVisible: !this.state.isUpdateModalVisible,
      idArticle: row.id,
      codPro: row.codPro,
      reference: row.ref,
      designation: row.designation,
      codeCat: row.categorie && row.categorie.codCat,

      qteAlert: parseFloat(row.qteAlert),
      stockMin: parseFloat(row.stockMin),

      codTyp: `${row.codTyp}`,
      idemBal: row.idemBal,  //Emballage
      codTvaVente: row.codTvaVente,
      taxeSpecifique: row.tauxTs,
      descriptionTs: row.dtsTs,
      codBarre: row.codBarre,
      puAchat: parseFloat(row.puAchat),
      especeUnite: row.especeUnite,
      usuel: `${row.usuel}`,
      codInterne: row.codInterne,
      //PAS DISPONIBLE DANS FICHIER ARTICLE
      puVente: parseFloat(row && row.prix && row.prix.prixUnitaire) || 0,
      prixDetaillant: row.prixDetaillant,
      prixGrossiste: row.prixGrossiste,
    })

  }
  handleCancelUpdate = () => {
    this.setState({
      isUpdateModalVisible: !this.state.isUpdateModalVisible,
      codPro: '',
      reference: '',
      designation: '',
      qteAlert: 0,
      stockMin: 0,
      codeCat: '',
      codTyp: '',
      idemBal: '',
      codTvaVente: '',
      taxeSpecifique: '',
      descriptionTs: '',
      codBarre: '',
      puAchat: '',
      especeUnite: '',
      usuel: '',
      codInterne: '',
      puVente: '',
      prixDetaillant: '',
      prixGrossiste: '',
    })
  }


  handleView = (row) => {
   // console.log('ROW', row)
    this.setState({
      isModalVisible: !this.state.isModalVisible,
      codPro: row.codPro,
      reference: row.ref,
      designation: row.designation,
      codeCat: row.categorie && row.categorie.codCat,

      qteAlert: parseFloat(row.qteAlert),
      stockMin: parseFloat(row.stockMin),

      codTyp: `${row.codTyp}`,
      idemBal: row.idemBal,  //Emballage
      codTvaVente: row.codTvaVente,
      taxeSpecifique: row.tauxTs,
      descriptionTs: row.dtsTs,
      codBarre: row.codBarre,
      puAchat: parseFloat(row.puAchat),
      especeUnite: row.especeUnite,
      usuel: `${row.usuel}`,
      codInterne: row.codInterne,
      //PAS DISPONIBLE DANS FICHIER ARTICLE
      puVente: parseFloat(row && row.prix && row.prix.prixUnitaire) || 0,
      prixDetaillant: row.prixDetaillant,
      prixGrossiste: row.prixGrossiste,


    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      codPro: '',
      reference: '',
      designation: '',
      qteAlert: 0,
      stockMin: 0,
      codeCat: '',
      codTyp: '',
      idemBal: '',
      codTvaVente: '',
      taxeSpecifique: '',
      descriptionTs: '',
      codBarre: '',
      puAchat: '',
      especeUnite: '',
      usuel: '',
      codInterne: '',
      puVente: '',
      prixDetaillant: '',
      prixGrossiste: '',
    });
  }

  handleChangeCategorie = (value) => {
    this.setState({ codeCat: value })
   // console.log(`selected ${value} ${this.state.codeCat}`);
  }

  handleBlur = () => {
  //  console.log('blur');
  }

  handleFocus = () => {
   // console.log('focus');
  }

  handleChangeType = (value) => {
    this.setState({ codTyp: value })
  }

  handleChangeTvaVente = (value) => {
    this.setState({ codTvaVente: value })
  }

  handleChangeUsuel = (value) => {
    this.setState({ usuel: value })
  }

  render() {
    const {
      isModalVisible,
      loading,
      codPro,
     
      designation,
      qteAlert,
      stockMin,
      codeCat,
      codTyp,
     
      codTvaVente,
      
      codBarre,
      puAchat,
    
      usuel,
     
      puVente,
      //prixDetaillant,
      //prixGrossiste,

      articles,
      categories,
      tva,

      //codCat,
      libCat,
      isAddModalVisible,
      isUpdateModalVisible,
      isAddCategorieModalVisible,

      isLoading,
      waitingEndMsg,

    } = this.state

    const InputGroup = Input.Group;
    const Option = Select.Option;

    const { SearchBar } = Search;

    const columnsArticles = [
      /*{
      dataField: 'id',
      text: ' ID'
    },*/
      {
        dataField: 'codPro',
        text: ' Code'
      }, 
      /*{
        dataField: 'codInterne',
        text: ' Référence '
      },*/
      {
        dataField: 'designation',
        text: ' Désignation '
      },
      /*{
        dataField: 'qteStock',
        text: ' Qté stocck'
      },
      {
        dataField: 'idemBal',
        text: ' Emballage'
      },*/
      {
        dataField: 'categorie.libCat',
        text: 'Catégorie '
      },
      {
        dataField: 'prix',
        text: 'Prix',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            < div
              style={{ textAlign: "center" }} className="d-flex">
                {parseFloat(row && row.prix && row.prix.prixUnitaire)}
            </div>
          );
        },
      },


      // (currentUser.agence.enCours===true && currentUser.slug==="ADMIN_ROLE"  ) &&
      {
        dataField: '',
        text: 'Action',
        headerAlign: 'center',
        sort: true,
        headerAttrs: { width: 50 },
        align: 'center',
        hidden: ( (currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "AGENT_ROLE") || (currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "ADMIN_ROLE") ) ? true: false,
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            < div
              style={{ textAlign: "center" }} className="d-flex">
              <Button
                type="primary"
                //fullWidth
                variant="contained"
                color="primary"
                size="small"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleView(row)}
              >
                <i className="icon icon-custom-view" />
              </Button>
              <Button
                hidden={use_logiciel === 'NO' ? false : true}
                type="primary"
                //fullWidth
                variant="contained"
                color="danger"
                size="small"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleUpdateOpen(row)}
              >
                <i className="icon icon-edit" />
              </Button>
              <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cet Article?" onConfirm={this.handleDeleteArticle} onCancel={this.cancelDelete} okText="Oui"
                cancelText="Non">
                <Button
                  hidden={use_logiciel === 'NO' ? false : true}
                  type="danger"
                  //fullWidth
                  variant="contained"
                  color="primary"
                  size="small"
                  //className="btn btn-success btn-sm"
                  onClick={() => this.handleDelete(row)}
                >
                  <i className="icon icon-alert-new" />
                </Button>

              </Popconfirm>

            </div>
          );
        },


      }

    ];


    const columnsArticlesPhone = [

      {
        dataField: 'designation',
        text: ' Désignation'
      },

      // (currentUser.agence.enCours===true && currentUser.slug==="ADMIN_ROLE"  ) &&
      {
        dataField: '',
        text: 'Action',
        headerAlign: 'center',
        sort: true,
        headerAttrs: { width: 50 },
        align: 'center',
        hidden: ( (currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "AGENT_ROLE") || (currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "ADMIN_ROLE") ) ? true : false,
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            < div
              style={{ textAlign: "center" }} className="d-flex">
              <Button
                type="primary"
                //fullWidth
                variant="contained"
                color="primary"
                size="small"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleView(row)}
              >
                <i className="icon icon-custom-view" />
              </Button>
              <Button
                type="primary"
                //fullWidth
                variant="contained"
                color="danger"
                size="small"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleUpdateOpen(row)}
              >
                <i className="icon icon-edit" />
              </Button>
              <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cet Article?" onConfirm={this.handleDeleteArticle} onCancel={this.cancelDelete} okText="Oui"
                cancelText="Non">
                <Button
                  type="danger"
                  //fullWidth
                  variant="contained"
                  color="primary"
                  size="small"
                  //className="btn btn-success btn-sm"
                  onClick={() => this.handleDelete(row)}
                >
                  <i className="icon icon-alert-new" />
                </Button>

              </Popconfirm>
            </div>
          );
        },


      }

    ];

    const defaultSorted = [{
      dataField: 'designation',
      order: 'asc'
    }];



    return (
      <div>
        <Card title="Liste des Articles">

          <Media query="(min-width: 600px)" render={() =>
          (
            <ToolkitProvider
              keyField="id"
              data={articles}
              columns={columnsArticles}
              //cellEdit={cellEditFactory({ mode: 'dbclick' })}
              search
              //insertRow={true}
              exportCSV

            >
              {
                props => (
                  <div>
                    <Row >
                      <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                        {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                        <SearchBar {...props.searchProps}
                          //delay={ 1000 }
                          style={{ maxWidth: 750, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                          placeholder="Rechercher"

                        />
                      </Col>
                      {(currentUser && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") && use_logiciel === 'NO' &&
                        <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                          <button style={{ marginLeft: 3 }}
                            className="btn bg-primary text-light rounded"
                            onClick={this.handleAddOpen}>
                            NOUVEAU <i className="icon icon-add" />
                          </button>
                        </Col>

                      }
                    </Row>


                    <BootstrapTable
                      {...props.baseProps}
                      //bootstrap4
                      wrapperClasses="table-responsive"
                      //selectRow={ selectRow }
                      //defaultSorted={defaultSorted}
                      pagination={paginationFactory()}
                      defaultSorted={defaultSorted}
                      striped
                      hover
                      condensed
                      headerWrapperClasses="foo"
                      noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                    //filter={filterFactory()}
                    />

                  </div>

                )
              }
            </ToolkitProvider>
          )}
          />


          <Media query="(max-width: 599px)" render={() =>
          (

            <ToolkitProvider
              keyField="id"
              data={articles}
              columns={columnsArticlesPhone}
              //cellEdit={cellEditFactory({ mode: 'dbclick' })}
              search
              //insertRow={true}
              exportCSV

            >
              {
                props => (
                  <div>
                    <Row >
                      <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                        <i className="fa fa-search" style={{ margin: 10 }}></i>

                        <SearchBar {...props.searchProps}
                          //delay={ 1000 }
                          style={{ maxWidth: 450, width: '125%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                          placeholder="Rechercher"

                        />
                      </Col>
                      {(currentUser && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") &&
                        <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                          <button style={{ marginLeft: 3 }}
                            className="btn bg-primary text-light rounded"
                            onClick={this.handleAddOpen}>
                            NOUVEAU <i className="icon icon-add" />
                          </button>
                        </Col>
                      }

                    </Row>

                    {
                      !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                    }
                    {
                      articles &&
                        Array.isArray(articles) &&
                        articles.length > 0 ?
                        <BootstrapTable
                          {...props.baseProps}
                          //bootstrap4
                          wrapperClasses="table-responsive"
                          //selectRow={ selectRow }
                          //defaultSorted={defaultSorted}
                          pagination={paginationFactory()}
                          defaultSorted={defaultSorted}
                          striped
                          hover
                          condensed
                          headerWrapperClasses="foo"
                          noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                        //filter={filterFactory()}
                        />
                        :
                        isLoading ?
                          <div style={{ justifyContent: 'center' }}>

                            <Row style={{ justifyContent: 'center' }}>
                              <Col style={{ justifyContent: 'center' }}>
                                <Spinner type="grow" color="primary" />
                                <Spinner type="grow" color="secondary" />
                                <Spinner type="grow" color="success" />
                                <Spinner type="grow" color="danger" />
                                <Spinner type="grow" color="warning" />
                                <Spinner type="grow" color="info" />
                                <Spinner type="grow" color="light" />
                                <Spinner type="grow" color="dark" />
                              </Col>
                            </Row> <br />
                          </div>
                          : null
                    }

                  </div>

                )
              }
            </ToolkitProvider>
          )}
          />
        </Card>


        <Modal
          width={800}
          visible={isAddModalVisible}
          title="Ajouter un Article"
          //onOk={this.handleAddOpen}
          onCancel={this.handleCancelAdd}
          footer={[
            <Button  type="primary" loading={loading} onClick={this.handleAddArticle}>
              Envoyer
            </Button>,
            <Button  type="danger" onClick={this.handleCancelAdd}>
              Annuler
            </Button>,

            /*<Button
             key="link"
             href="https://google.com"
             type="primary"
             loading={loading}
             onClick={this.handleOk}
           >
             Search on Google
           </Button>,*/
          ]}
        >
          <Form>
            <Row form>
              {/*<Col md={6}>
                <FormGroup>
                  <Label for="codPro">Code Produit</Label>
                  <Input type="number" name="codPro" id="codPro" value={codPro} onChange={this.handleChange} placeholder="" />
                </FormGroup>
               </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="codInterne">Référence Article</Label>
                  <Input type="text" name="codInterne" value={codInterne} id="codInterne" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col> */}

              <Col md={12}>
                <FormGroup>
                  <Label for="designation">Désignation</Label>
                  <Input type="text" name="designation" value={designation} onChange={this.handleChange} id="designation" placeholder="" />
                </FormGroup>
              </Col>
            </Row>



            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="qteAlert">Quantité Alerte</Label>
                  <Input type="number" name="qteAlert" id="qteAlert" value={qteAlert} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="stockMin">Stock Minimun</Label>
                  <Input type="number" name="stockMin" value={stockMin} id="stockMin" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="codeCat">Catégorie</Label>
                  <InputGroup compact>
                    <Select
                      value={codeCat}
                      showSearch
                      //style={{width: 200}}
                      style={{ width: '85%' }}
                      placeholder="Selectionner Catégorie"
                      optionFilterProp="children"
                      onChange={this.handleChangeCategorie}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {
                        categories && categories.length ?
                          categories.map((categorie, k) => {
                            return <Option key={k} value={categorie.codCat}>{categorie.libCat}</Option>
                          }) : null
                      }

                    </Select>
                    <Button onClick={this.openCategorieAdd} style={{ width: '15%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                  </InputGroup>
                </FormGroup>

              </Col>

            </Row>


            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="codTyp">Type</Label>
                  <Select className="gx-mr-3 gx-mb-3" defaultValue="1" value={codTyp} onChange={this.handleChangeType}>
                    <Option value="1">Produit</Option>
                    <Option value="2">Services</Option>
                  </Select>

                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="codTvaVente">TVA Vente</Label>
                  <Select className="gx-mr-3 gx-mb-3" defaultValue="E" value={codTvaVente} onChange={this.handleChangeTvaVente}>
                    {
                      tva && tva.length ?
                        tva.map((tva, k) => {
                          return <Option value={tva.codTva}>{tva.libTva}</Option>
                        }) : null
                    }
                  </Select>
                  {(codTvaVente === "A" || codTvaVente === "C" || codTvaVente === "E" || codTvaVente === "F") &&
                    <h6 style={{ color: "red" }}><span>Taux: 0%</span></h6>
                  }
                  {(codTvaVente === "B" || codTvaVente === "D") &&
                    <h6 style={{ color: "red" }}><span>Taux: 18%</span></h6>
                  }

                </FormGroup>
              </Col>

            </Row>

            {/*  <Row form>
             <Col md={6}>
                <FormGroup >
                  <Label for="idemBal">Emballage</Label>
                  <Input disabled type="text" name="idemBal" value={idemBal} id="idemBal" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
                   
              <Col md={6}>
                <FormGroup>
                  <Label for="taxeSpecifique">Taxe Spécifique</Label>
                  <Input type="text" name="taxeSpecifique" value={taxeSpecifique} id="taxeSpecifique" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label for="descriptionTs">Description TS</Label>
              <Input type="text" name="descriptionTs" value={descriptionTs} id="descriptionTs" onChange={this.handleChange} placeholder="" />
            </FormGroup>
 */}
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="codBarre">Code Barre</Label>
                  <Input type="number" name="codBarre" value={codBarre} id="codBarre" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="usuel">Usuel</Label>
                  <Select className="gx-mr-3 gx-mb-3" defaultValue="1" value={usuel} onChange={this.handleChangeUsuel}>
                    <Option value="1">Usuel</Option>
                    <Option value="0">Non Usuel</Option>

                  </Select>
                </FormGroup>
              </Col>

            </Row>


            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="puAchat">PU Achat</Label>
                  <Input type="number" name="puAchat" id="puAchat" value={puAchat} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="puVente">Prix Vente</Label>
                  <Input type="number" name="puVente" id="puVente" value={puVente} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>

            </Row>

            {/* <Row form>
            <Col md={6}>
                <FormGroup>
                  <Label for="especeUnite">Espèce des Vente</Label>
                  <Input type="text" name="especeUnite" value={especeUnite} id="especeUnite" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="reference">Référence</Label>
                  <Input type="text" name="reference" value={reference} id="reference" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              
            </Row>
           
            <Row form>
              
              <Col md={4}>
                <FormGroup>
                  <Label for="prixDetaillant">Prix Détaillant</Label>
                  <Input type="number" name="prixDetaillant" value={prixDetaillant} id="prixDetaillant" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="prixGrossiste">Prix Grossiste</Label>
                  <Input type="number" name="prixGrossiste" value={prixGrossiste} id="prixGrossiste" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>
            */}

          </Form>
        </Modal>

        <Modal
          width={800}
          visible={isUpdateModalVisible}
          title="Modifier l'Article"
          //onOk={this.handleAddOpen}
          onCancel={this.handleCancelUpdate}
          footer={[
            <Button  type="primary" loading={loading} onClick={this.handleUpdateArticle}>
              Modifier
            </Button>,
            <Button  type="danger" onClick={this.handleCancelUpdate}>
              Annuler
            </Button>,

            /*<Button
             key="link"
             href="https://google.com"
             type="primary"
             loading={loading}
             onClick={this.handleOk}
           >
             Search on Google
           </Button>,*/
          ]}
        >
          <Form>
            <Row form>
              <Col md={3}>
                <FormGroup>
                  <Label for="codPro">Code Produit</Label>
                  <Input disabled type="number" name="codPro" id="codPro" value={codPro} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              {/*<Col md={6}>
                <FormGroup>
                  <Label for="codInterne">Référence Article</Label>
                  <Input type="text" name="codInterne" value={codInterne} id="codInterne" onChange={this.handleChange} placeholder="" />
                </FormGroup>
                </Col>*/}

              <Col md={9}>
                <FormGroup>
                  <Label for="designation">Désignation</Label>
                  <Input type="text" name="designation" value={designation} onChange={this.handleChange} id="designation" placeholder="" />
                </FormGroup>
              </Col>
            </Row>



            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="qteAlert">Quantité Alerte</Label>
                  <Input type="number" name="qteAlert" id="qteAlert" value={qteAlert} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="stockMin">Stock Minimun</Label>
                  <Input type="number" name="stockMin" value={stockMin} id="stockMin" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="codeCat">Catégorie</Label>
                  <InputGroup compact>
                    <Select
                      value={codeCat}
                      showSearch
                      //style={{width: 200}}
                      style={{ width: '85%' }}
                      placeholder="Selectionner Catégorie"
                      optionFilterProp="children"
                      onChange={this.handleChangeCategorie}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {
                        categories && categories.length ?
                          categories.map((categorie, k) => {
                            return <Option key={k} value={categorie.codCat}>{categorie.libCat}</Option>
                          }) : null
                      }

                    </Select>
                    <Button onClick={this.openCategorieAdd} style={{ width: '15%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                  </InputGroup>
                </FormGroup>

              </Col>

            </Row>


            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="codTyp">Type</Label>
                  <Select className="gx-mr-3 gx-mb-3" defaultValue="1" value={codTyp} onChange={this.handleChangeType}>
                    <Option value="1">Produit</Option>
                    <Option value="2">Services</Option>
                  </Select>

                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="codTvaVente">TVA Vente</Label>
                  <Select className="gx-mr-3 gx-mb-3" defaultValue="E" value={codTvaVente} onChange={this.handleChangeTvaVente}>
                    <Option value="A">A</Option>
                    <Option value="B">B</Option>
                    <Option value="C">C</Option>
                    <Option value="D">D</Option>
                    <Option value="E">E</Option>
                    <Option value="F">F</Option>
                  </Select>
                  {(codTvaVente === "A" || codTvaVente === "C" || codTvaVente === "E" || codTvaVente === "F") &&
                    <h6 style={{ color: "red" }}><span>Taux: 0%</span></h6>
                  }
                  {(codTvaVente === "B" || codTvaVente === "D") &&
                    <h6 style={{ color: "red" }}><span>Taux: 18%</span></h6>
                  }

                </FormGroup>
              </Col>

            </Row>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="codBarre">Code Barre</Label>
                  <Input type="number" name="codBarre" value={codBarre} id="codBarre" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="usuel">Usuel</Label>
                  <Select className="gx-mr-3 gx-mb-3" defaultValue="1" value={usuel} onChange={this.handleChangeUsuel}>
                    <Option value="1">Usuel</Option>
                    <Option value="0">Non Usuel</Option>

                  </Select>
                </FormGroup>
              </Col>

            </Row>


            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="puAchat">PU Achat</Label>
                  <Input type="number" name="puAchat" id="puAchat" value={puAchat} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="puVente">Prix Vente</Label>
                  <Input type="number" name="puVente" id="puVente" value={puVente} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>

            </Row>

          </Form>
        </Modal>

        <Modal
          width={800}
          visible={isModalVisible}
          title="Détails Article"
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          footer={[
            <Button  type="danger" onClick={this.handleCancel}>
              Retour
            </Button>,
            /*<Button  type="primary" loading={loading} onClick={this.handleCancel}>
              Envoyer
            </Button>,
            <Button
              key="link"
              href="https://google.com"
              type="primary"
              loading={loading}
              onClick={this.handleOk}
            >
              Search on Google
            </Button>,*/
          ]}
        >
          <Form>
            <Row form>
              <Col md={3}>
                <FormGroup>
                  <Label for="codPro">Code Produit</Label>
                  <Input disabled type="number" name="codPro" id="codPro" value={codPro} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              {/*<Col md={6}>
                <FormGroup>
                  <Label for="codInterne">Référence Article</Label>
                  <Input disabled type="text" name="codInterne" value={codInterne} id="codInterne" onChange={this.handleChange} placeholder="" />
                </FormGroup>
                </Col>*/}

              <Col md={9}>
                <FormGroup>
                  <Label for="designation">Désignation</Label>
                  <Input disabled type="text" name="designation" value={designation} onChange={this.handleChange} id="designation" placeholder="" />
                </FormGroup>
              </Col>
            </Row>



            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="qteAlert">Quantité Alerte</Label>
                  <Input disabled type="number" name="qteAlert" id="qteAlert" value={qteAlert} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="stockMin">Stock Minimun</Label>
                  <Input disabled type="number" name="stockMin" value={stockMin} id="stockMin" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="codeCat">Catégorie</Label>
                  <InputGroup compact>
                    <Select
                      disabled
                      value={codeCat}
                      showSearch
                      //style={{width: 200}}
                      style={{ width: '85%' }}
                      placeholder="Selectionner Catégorie"
                      optionFilterProp="children"
                      onChange={this.handleChangeCategorie}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {
                        categories && categories.length ?
                          categories.map((categorie, k) => {
                            return <Option key={k} value={categorie.codCat}>{categorie.libCat}</Option>
                          }) : null
                      }

                    </Select>
                    <Button disabled onClick={this.openCategorieAdd} style={{ width: '15%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                  </InputGroup>
                </FormGroup>

              </Col>

            </Row>


            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="codTyp">Type</Label>
                  <Select disabled className="gx-mr-3 gx-mb-3" defaultValue="1" value={codTyp} onChange={this.handleChangeType}>
                    <Option value="1">Produit</Option>
                    <Option value="2">Services</Option>
                  </Select>

                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="codTvaVente">TVA Vente</Label>
                  <Select disabled className="gx-mr-3 gx-mb-3" defaultValue="E" value={codTvaVente} onChange={this.handleChangeTvaVente}>
                    <Option value="A">A</Option>
                    <Option value="B">B</Option>
                    <Option value="C">C</Option>
                    <Option value="D">D</Option>
                    <Option value="E">E</Option>
                    <Option value="F">F</Option>
                  </Select>
                  {(codTvaVente === "A" || codTvaVente === "C" || codTvaVente === "E" || codTvaVente === "F") &&
                    <h6 style={{ color: "red" }}><span>Taux: 0%</span></h6>
                  }
                  {(codTvaVente === "B" || codTvaVente === "D") &&
                    <h6 style={{ color: "red" }}><span>Taux: 18%</span></h6>
                  }

                </FormGroup>
              </Col>

            </Row>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="codBarre">Code Barre</Label>
                  <Input disabled type="number" name="codBarre" value={codBarre} id="codBarre" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="usuel">Usuel</Label>
                  <Select disabled className="gx-mr-3 gx-mb-3" defaultValue="1" value={usuel} onChange={this.handleChangeUsuel}>
                    <Option value="1">Usuel</Option>
                    <Option value="0">Non Usuel</Option>

                  </Select>
                </FormGroup>
              </Col>

            </Row>


            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="puAchat">PU Achat</Label>
                  <Input disabled type="number" name="puAchat" id="puAchat" value={puAchat} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="puVente">Prix Vente</Label>
                  <Input disabled type="number" name="puVente" id="puVente" value={puVente} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>

            </Row>

          </Form>
        </Modal>

        <Modal
          width={600}
          visible={isAddCategorieModalVisible}
          title="Ajouter une Catégorie"
          //onOk={this.handleAddOpen}
          onCancel={this.openCategorieAdd}
          footer={[
            <Button  type="primary" loading={loading} onClick={this.handleAddCategorie}>
              Envoyer
            </Button>,
            <Button  type="danger" onClick={this.openCategorieAdd}>
              Annuler
            </Button>,

          ]}
        >
          <Form>
            <Row form>
              {/*<Col md={3}>
                <FormGroup>
                  <Label for="codCat">Code Catégorie</Label>
                  <Input type="number" name="codCat" id="codCat" value={codCat} onChange={this.handleChange} placeholder="" />
                </FormGroup>
        </Col>*/}
              <Col md={12}>
                <FormGroup>
                  <Label for="libCat">Libellé</Label>
                  <Input type="text" name="libCat" value={libCat} onChange={this.handleChange} id="libCat" placeholder="" />
                </FormGroup>
              </Col>
            </Row>


          </Form>
        </Modal>

      </div>
    )
  }
}


export default Articles;
