import React from "react";
import { Card, Button, Modal, message, Popconfirm } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import Media from 'react-media';

import { services } from '../../../services/services'

import { authenticationService } from "../../../services/authentication-service";
import { use_logiciel } from '../../../env'


const currentUser = authenticationService.currentUserValue;

class Categories extends React.Component {
  state = {
    isModalVisible: false,
    isAddModalVisible: false,
    isUpdateModalVisible: false,
    loading: false,
    categories: [],

    usuel: "1",
    codTyp: "1",
    libCat: '',
    codeCat: '',
    emailFour: '',
  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  componentDidMount() {
    services.getCategories()
      .then(categories => {
      //  console.log('Categories', categories)
        this.setState({ categories })
      })


  }
  handleAddOpen = () => {
    this.setState({ isAddModalVisible: !this.state.isAddModalVisible, });
  };

  handleCancelAdd = () => {
    this.setState({
      isAddModalVisible: !this.state.isAddModalVisible,
      codCat: '',

      libCat: '',

    })
  }

  handleAddCategorie = () => {
    const {
      //codCat,
      libCat,

    } = this.state


    if (libCat === '') {
      message.warning('Veuillez préciser la Raison sociale', 5)
      return;
    }
    /*if (codCat === '') {
      message.warning('Veuillez préciser le code de la Catégorie', 5)
      return;
    }*/

    this.setState({ loading: true })
    let req = {
      // codCat,
      libCat: libCat.toUpperCase(),

      //libCat: libCat.toUpperCase(),

    }
    services.addCategorie(req)
      .then(result => {
       // console.log(result)
        this.setState({
          codCat: '',
          loading: false,
          libCat: '',

          isAddModalVisible: !this.state.isAddModalVisible,

        })
        message.success('Categorie ajouter avec succès', 10)

        services.getCategories()
          .then(categories => {
            this.setState({ categories })
          })

      })

      .catch((err) => {
        message.error(err, 10)
      });
  }

  handleUpdateCategorie = () => {
    const {
      idCategorie,
      codCat,
      libCat,


    } = this.state


    if (libCat === '') {
      message.warning('Veuillez préciser la Raison sociale', 5)
      return;
    }
    /*if (codCat === '' ) {
      message.warning('Veuillez précise le Code de la Categorie', 5)
      return;
    }*/

    let req = {
      idCategorie,
      codCat,

      libCat: libCat.toUpperCase(),



    }
    services.updateCategorie(codCat, req)
      .then(result => {
       // console.log(result)
        this.setState({
          idCategorie: '',
          codCat: '',
          libCat: '',
          isUpdateModalVisible: !this.state.isUpdateModalVisible,

        })
        message.success('Catégorie modifier avec succès', 10)

        services.getCategories()
          .then(categories => {
            this.setState({ categories })
          })

      })

      .catch(() => {
        message.error('Une erreur est survenue lors de la modification, Veuillez réessayer plus tard', 10)
      });
  }

  handleDelete = (row) => {
    this.setState({
      //idCategorie: row.id,
      codCat: row.codCat,
      libCat: row.libCat,
    })

  }

  handleDeleteCategorie = () => {

    const { codCat } = this.state

    services.deleteCategorie(codCat)
      .then(del => {
        //console.log('delete user', del)
        message.success('Categorie supprimer avec succès', 10)
        services.getCategories()
          .then(categories => {
            this.setState({ categories })
          })

      })
      .catch((err) => {
        message.error(err, 10)
      });

  }

  handleUpdateOpen = (row) => {
    this.setState({
      isUpdateModalVisible: !this.state.isUpdateModalVisible,
      //idCategorie: row.id,
      codCat: row.codCat,
      libCat: row.libCat,

    })

  }
  handleCancelUpdate = () => {
    this.setState({
      isUpdateModalVisible: !this.state.isUpdateModalVisible,
      codCat: '',

      libCat: '',

    })
  }


  handleView = (row) => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
      codCat: row.codCat,

      libCat: row.libCat,




    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      codCat: '',

      libCat: '',
    });
  }


  render() {
    const {
      isModalVisible,
      loading,
      codCat,

      libCat,

      categories,


      isAddModalVisible,
      isUpdateModalVisible
    } = this.state


   // const Option = Select.Option;

    const { SearchBar } = Search;

    const columnsCategories = [
      /*{
      dataField: 'id',
      text: ' ID',
      headerAttrs: { width: 50 },
    },*/ {
        dataField: 'codCat',
        text: ' Code Catégorie',
        headerAttrs: { width: 100 },
      }, {
        dataField: 'libCat',
        text: ' Libellé ',
        headerAttrs: { width: 400 },
      },


      //(currentUser.agence.enCours===true && currentUser.slug==="ADMIN_ROLE"  ) &&
      {
        dataField: '',
        text: 'Action',
        headerAlign: 'center',
        sort: true,
        headerAttrs: { width: 50 },
        align: 'center',
        hidden: ( (currentUser && currentUser.agence.enCours === false && currentUser.slug === "AGENT_ROLE") || (currentUser.agence.enCours === false && currentUser.slug === "ADMIN_ROLE") ) && true,
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            < div
              style={{ display: "flex", justifyContent: "center", textAlign: "center" }} className="d-flex">
              <Button
                type="primary"
                //fullWidth
                variant="contained"
                color="primary"
                size="small"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleView(row)}
              >
                <i className="icon icon-custom-view" />
              </Button>
              <Button
                hidden={use_logiciel === 'NO' ? false : true}
                type="primary"
                //fullWidth
                variant="contained"
                color="danger"
                size="small"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleUpdateOpen(row)}
              >
                <i className="icon icon-edit" />
              </Button>
              <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cette Categorie?" onConfirm={this.handleDeleteCategorie} onCancel={this.cancelDelete} okText="Oui"
                cancelText="Non">
                <Button
                  hidden={use_logiciel === 'NO' ? false : true}
                  type="danger"
                  //fullWidth
                  variant="contained"
                  color="primary"
                  size="small"
                  //className="btn btn-success btn-sm"
                  onClick={() => this.handleDelete(row)}
                >
                  <i className="icon icon-alert-new" />
                </Button>

              </Popconfirm>

            </div>
          );
        },

      }

    ];


    const columnsCategoriesPhone = [

      {
        dataField: 'libCat',
        text: ' Libellé'
      },

      //(currentUser.agence.enCours===true && currentUser.slug==="ADMIN_ROLE"  ) &&   
      {
        dataField: '',
        text: 'Action',
        headerAlign: 'center',
        sort: true,
        headerAttrs: { width: 50 },
        align: 'center',
        hidden: ( (currentUser && currentUser.agence.enCours === false && currentUser.slug === "AGENT_ROLE") || (currentUser.agence.enCours === false && currentUser.slug === "ADMIN_ROLE") ) && true,
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            < div
              style={{ textAlign: "center" }} className="d-flex">
              <Button
                type="primary"
                //fullWidth
                variant="contained"
                color="primary"
                size="small"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleView(row)}
              >
                <i className="icon icon-custom-view" />
              </Button>
              <Button
                hidden={use_logiciel === 'NO' ? false : true}
                type="primary"
                //fullWidth
                variant="contained"
                color="danger"
                size="small"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleUpdateOpen(row)}
              >
                <i className="icon icon-edit" />
              </Button>
              <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cette Categorie?" onConfirm={this.handleDeleteCategorie} onCancel={this.cancelDelete} okText="Oui"
                cancelText="Non">
                <Button
                  hidden={use_logiciel === 'NO' ? false : true}
                  type="danger"
                  //fullWidth
                  variant="contained"
                  color="primary"
                  size="small"
                  //className="btn btn-success btn-sm"
                  onClick={() => this.handleDelete(row)}
                >
                  <i className="icon icon-alert-new" />
                </Button>

              </Popconfirm>

            </div>
          );
        },

      }

    ];

    const defaultSorted = [{
      dataField: 'libCat',
      order: 'asc'
    }];



    return (
      <div>
        <Card title="Liste des Categories">

          <Media query="(min-width: 600px)" render={() =>
          (
            <ToolkitProvider
              keyField="id"
              data={categories}
              columns={columnsCategories}
              //cellEdit={cellEditFactory({ mode: 'dbclick' })}
              search
              //insertRow={true}
              exportCSV

            >
              {
                props => (
                  <div>
                    <Row >
                      <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                        {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                        <SearchBar {...props.searchProps}
                          //delay={ 1000 }
                          style={{ maxWidth: 750, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                          placeholder="Rechercher"

                        />
                      </Col>
                      {currentUser && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE" && use_logiciel === 'NO' &&
                        <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                          <button style={{ marginLeft: 3 }}
                            className="btn bg-primary text-light rounded"
                            onClick={this.handleAddOpen}>
                            NOUVEAU <i className="icon icon-add" />
                          </button>
                        </Col>

                      }
                    </Row>


                    <BootstrapTable
                      {...props.baseProps}
                      //bootstrap4
                      wrapperClasses="table-responsive"
                      //selectRow={ selectRow }
                      //defaultSorted={defaultSorted}
                      pagination={paginationFactory()}
                      defaultSorted={defaultSorted}
                      striped
                      hover
                      condensed
                      headerWrapperClasses="foo"
                      noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                    //filter={filterFactory()}
                    />

                  </div>

                )
              }
            </ToolkitProvider>
          )}
          />


          <Media query="(max-width: 599px)" render={() =>
          (

            <ToolkitProvider
              keyField="id"
              data={categories}
              columns={columnsCategoriesPhone}
              //cellEdit={cellEditFactory({ mode: 'dbclick' })}
              search
              //insertRow={true}
              exportCSV

            >
              {
                props => (
                  <div>
                    <Row >
                      <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                        <i className="fa fa-search" style={{ margin: 10 }}></i>

                        <SearchBar {...props.searchProps}
                          //delay={ 1000 }
                          style={{ maxWidth: 450, width: '125%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                          placeholder="Rechercher"

                        />
                      </Col>
                      {currentUser && (currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") &&
                        <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                          <button style={{ marginLeft: 3 }}
                            className="btn bg-primary text-light rounded"
                            onClick={this.handleAddOpen}>
                            NOUVEAU <i className="icon icon-add" />
                          </button>
                        </Col>
                      }

                    </Row>

                    <BootstrapTable
                      {...props.baseProps}
                      //bootstrap4
                      wrapperClasses="table-responsive"
                      //selectRow={ selectRow }
                      //defaultSorted={defaultSorted}
                      pagination={paginationFactory()}
                      defaultSorted={defaultSorted}
                      striped
                      hover
                      condensed
                      headerWrapperClasses="foo"
                      noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                    //filter={filterFactory()}
                    />

                  </div>

                )
              }
            </ToolkitProvider>
          )}
          />
        </Card>


        <Modal
          width={600}
          visible={isAddModalVisible}
          title="Ajouter une Categorie"
          //onOk={this.handleAddOpen}
          onCancel={this.handleCancelAdd}
          footer={[
            <Button  type="primary" loading={loading} onClick={this.handleAddCategorie}>
              Envoyer
            </Button>,
            <Button  type="danger" onClick={this.handleCancelAdd}>
              Annuler
            </Button>,

          ]}
        >
          <Form>
            <Row form>
              {/*<Col md={3}>
                <FormGroup>
                  <Label for="codCat">Code Catégorie</Label>
                  <Input type="number" name="codCat" id="codCat" value={codCat} onChange={this.handleChange} placeholder="" />
                </FormGroup>
        </Col>*/}
              <Col md={12}>
                <FormGroup>
                  <Label for="libCat">Libellé</Label>
                  <Input type="text" name="libCat" value={libCat} onChange={this.handleChange} id="libCat" placeholder="" />
                </FormGroup>
              </Col>
            </Row>


          </Form>
        </Modal>

        <Modal
          width={600}
          visible={isUpdateModalVisible}
          title="Modifier la Catégorie"
          //onOk={this.handleAddOpen}
          onCancel={this.handleCancelUpdate}
          footer={[
            <Button  type="primary" loading={loading} onClick={this.handleUpdateCategorie}>
              Modifier
            </Button>,
            <Button  type="danger" onClick={this.handleCancelUpdate}>
              Annuler
            </Button>,

          ]}
        >
          <Form>
            <Row form>
              <Col md={3}>
                <FormGroup>
                  <Label for="codCat">Code Catégorie</Label>
                  <Input disabled type="number" name="codCat" id="codCat" value={codCat} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={9}>
                <FormGroup>
                  <Label for="libCat">Libellé</Label>
                  <Input type="text" name="libCat" value={libCat} onChange={this.handleChange} id="libCat" placeholder="" />
                </FormGroup>
              </Col>
            </Row>


          </Form>
        </Modal>

        <Modal
          width={600}
          visible={isModalVisible}
          title="Détails Categorie"
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          footer={[
            <Button  type="danger" onClick={this.handleCancel}>
              Retour
            </Button>,

          ]}
        >
          <Form>
            <Row form>
              <Col md={3}>
                <FormGroup>
                  <Label for="codCat">Code Catégorie</Label>
                  <Input disabled type="number" name="codCat" id="codCat" value={codCat} onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
              <Col md={9}>
                <FormGroup>
                  <Label for="libCat">Libellé</Label>
                  <Input disabled type="text" name="libCat" value={libCat} onChange={this.handleChange} id="libCat" placeholder="" />
                </FormGroup>
              </Col>
            </Row>

          </Form>
        </Modal>

      </div>
    )
  }
}


export default Categories;
