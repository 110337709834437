import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { authenticationService } from "../../services/authentication-service";
//import { services } from "../../services/services";

import { Link } from "react-router-dom";

/*const signOut = (e) => {
  e.preventDefault()
  authenticationService.logout()
  this.props.history.push('/signup')
}*/

const currentUser = authenticationService.currentUserValue



class UserProfile extends Component {
  state = {
    // currentUser:'',
    ip: "",
    location: "",
    longitude: "",
    latitude: "",
    departement: "",
    action: "DECONNEXION"
  }

  signOut = (e) => {
    e.preventDefault()
    authenticationService.logout()
    this.props.history.push('/signup')

    /*services.activityLog(this.state.ip, this.state.location, this.state.departement, this.state.longitude, this.state.latitude, this.state.action)
          .then(activity => {
            //console.log("DDD", activity)
            e.preventDefault()
            authenticationService.logout()
            this.props.history.push('/signup')
              
          })
          .catch(err => {
            
            //console.log('ERR', err)
          })*/

  }

  /*componentDidMount() {

    fetch(
      "https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572"
    )
      .then(response => response.json())
      .then((data) => {
        //console.log("DATA", data)
        this.setState(
          {
            data,
            ip: data.IPv4,
            location: `${data.city}, ${data.country_name}(${data.country_code})`,
            departement: data.state,
            latitude: data.latitude,
            longitude: data.longitude,
          }
        )
      });


  }*/

  /*componentDidMount() {
    services.getCurrentUser()
    .then(currentUser => {
      console.log('AUTHCURRENT', currentUser)
      this.setState(currentUser)
    } )
    }*/

  render() {
    /*const{
      currentUser,
    }= this.state
    */

    console.log('THISSTATE', this.state)
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li><Link to="/profile">Profil</Link></li>

        <li onClick={this.signOut/*() => this.props.userSignOut()*/}>Se Déconnecter
        </li>
      </ul>


    );

    return (

      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <Avatar src={require("assets/images/User.png")}
            className="gx-size-40 gx-pointer gx-mr-3" alt="" />
          <span className="gx-avatar-name">{currentUser ? currentUser.prenomAgent : ''} {currentUser ? (currentUser.nomAgent ? currentUser.nomAgent.toUpperCase(): '') : ''}<i
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
        </Popover>
      </div>

    )

  }
}

export default connect(null, { userSignOut })(UserProfile);
