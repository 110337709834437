import React from "react";
import { Card, Button, Modal, message, Popconfirm } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, Form, FormGroup, Label, Input, } from 'reactstrap';
import Media from 'react-media';

import { services } from '../../../services/services';
import { use_logiciel } from '../../../env'




class NatureRecette extends React.Component {
  state = {
    isModalVisible: false,
    isAddModalVisible: false,
    isUpdateModalVisible: false,
    loading: false,
    natures: [],

    codeNatRec: '',
    libelleNatRec: '',


  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  componentDidMount() {
    services.getNatureRecette()
      .then(natures => {
        console.log('natures', natures)
        this.setState({ natures })
      })


  }
  handleAddOpen = () => {
    this.setState({ isAddModalVisible: !this.state.isAddModalVisible, });
  };

  handleCancelAdd = () => {
    this.setState({
      isAddModalVisible: !this.state.isAddModalVisible,
      codeNatRec: '',
      libelleNatRec: '',

    })
  }

  handleAddNature = () => {
    const {
      //codAgence,
      libelleNatRec,

    } = this.state


    if (libelleNatRec === '') {
      message.warning('Veuillez préciser le libellle de la Nature de recette', 5)
      return;
    }
    /*if (codAgence === '') {
      message.warning('Veuillez sélectionner une Agence', 5)
      return;
    }*/

    this.setState({ loading: true })

    let req = {

      libelleNatRec: libelleNatRec.toUpperCase(),
    }
    services.addNatureRecette(req)
      .then(result => {
        // console.log(result)
        this.setState({
          libelleNatRec: '',

          loading: false,
          isAddModalVisible: !this.state.isAddModalVisible,

        })
        message.success('Nature ajouter avec succès', 10)

        services.getNatureRecette()
          .then(natures => {
            this.setState({ natures })
          })

      })

      .catch((err) => {
        this.setState({ loading: false })
        message.error(err, 10)

      });
  }

  handleUpdateNature = () => {
    const {

      codeNatRec,
      libelleNatRec,

    } = this.state


    if (libelleNatRec === '') {
      message.warning('Veuillez préciser le libellle de la Nature de recette', 5)
      return;
    }
    if (codeNatRec === '') {
      message.warning('Veuillez sélectionner le Code de la nature de recette', 5)
      return;
    }

    let req = {
      codeNatRec,
      libelleNatRec: libelleNatRec.toUpperCase(),
    }
    services.updateNatureRecette(req)
      .then(result => {
        // console.log(result)
        this.setState({
          codeNatRec: '',
          libelleNatRec: '',

          isUpdateModalVisible: !this.state.isUpdateModalVisible,

        })
        message.success('Nature modifier avec succès', 10)

        services.getNatureRecette()
          .then(natures => {
            this.setState({ natures })
          })

      })

      .catch(() => {
        message.error('Une erreur est survenue lors de la modification, Veuillez réessayer plus tard', 10)
      });
  }

  handleDelete = (row) => {
    this.setState({
      codeNatRec: row.codeNatRec,
      libelleNatRec: row.libelleNatRec,
    })

  }

  handleDeleteNature = () => {

    const { codeNatRec } = this.state

    services.deleteNatureRecette(codeNatRec)
      .then(del => {
        //console.log('delete user', del)
        message.success('Nature supprimer avec succès', 10)
        services.getNatureRecette()
          .then(natures => {
            this.setState({ natures })
          })

      })
      .catch((err) => {
        message.error(err, 10)
      });

  }

  handleUpdateOpen = (row) => {
    this.setState({
      isUpdateModalVisible: !this.state.isUpdateModalVisible,
      //idAgence: row.id,
      codeNatRec: row.codeNatRec,
      libelleNatRec: row.libelleNatRec,

    })

  }
  handleCancelUpdate = () => {
    this.setState({
      isUpdateModalVisible: !this.state.isUpdateModalVisible,
      codeNatRec: '',
      libelleNatRec: '',

    })
  }


  handleView = (row) => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
      codeNatRec: row.codeNatRec,
      libelleNatRec: row.libelleNatRec,

    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      codeNatRec: '',
      libelleNatRec: '',
    });
  }


  render() {
    const {
      isModalVisible,
      loading,
      //codeNatRec,
      libelleNatRec,
      natures,


      isAddModalVisible,
      isUpdateModalVisible
    } = this.state


    //const Option = Select.Option;

    const { SearchBar } = Search;

    const columnsNatures = [
      /*{
      dataField: 'id',
      text: ' ID'
    },*/ {
        dataField: 'codeNatRec',
        text: ' Code',
        headerAttrs: { width: 100 },

      }, {
        dataField: 'libelleNatRec',
        text: ' Libellé'
      },


      {
        dataField: '',
        text: 'Action',
        headerAlign: 'center',
        sort: true,
        headerAttrs: { width: 50 },
        align: 'center',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            < div
              style={{ textAlign: "center" }} className="d-flex">
              <Button

                //hidden= { use_logiciel === 'NO' ? false : true}
                type="primary"
                //fullWidth
                variant="contained"
                color="primary"
                size="small"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleView(row)}
              >
                <i className="icon icon-custom-view" />
              </Button>
              <Button
                hidden={use_logiciel === 'NO' ? false : true}
                type="primary"
                //fullWidth
                variant="contained"
                color="danger"
                size="small"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleUpdateOpen(row)}
              >
                <i className="icon icon-edit" />
              </Button>
              <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cette Nature de recette?" onConfirm={this.handleDeleteNature} onCancel={this.cancelDelete} okText="Oui"
                cancelText="Non">
                <Button
                  hidden={use_logiciel === 'NO' ? false : true}
                  type="danger"
                  //fullWidth
                  variant="contained"
                  color="primary"
                  size="small"
                  //className="btn btn-success btn-sm"
                  onClick={() => this.handleDelete(row)}
                >
                  <i className="icon icon-alert-new" />
                </Button>

              </Popconfirm>

            </div>
          );
        },

      }

    ];


    const columnsNaturesPhone = [

      {
        dataField: 'libelleNatRec',
        text: ' Libellé'
      },


      {
        dataField: '',
        text: 'Action',
        headerAlign: 'center',
        sort: true,
        headerAttrs: { width: 50 },
        align: 'center',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            < div
              style={{ textAlign: "center" }} className="d-flex">
              <Button
                type="primary"
                //fullWidth
                variant="contained"
                color="primary"
                size="small"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleView(row)}
              >
                <i className="icon icon-custom-view" />
              </Button>
              <Button
                hidden={use_logiciel === 'NO' ? false : true}
                type="primary"
                //fullWidth
                variant="contained"
                color="danger"
                size="small"
                //className="btn btn-success btn-sm"
                onClick={() => this.handleUpdateOpen(row)}
              >
                <i className="icon icon-edit" />
              </Button>
              <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cette Nature de recette?" onConfirm={this.handleDeleteNature} onCancel={this.cancelDelete} okText="Oui"
                cancelText="Non">
                <Button
                  hidden={use_logiciel === 'NO' ? false : true}
                  type="danger"
                  //fullWidth
                  variant="contained"
                  color="primary"
                  size="small"
                  //className="btn btn-success btn-sm"
                  onClick={() => this.handleDelete(row)}
                >
                  <i className="icon icon-alert-new" />
                </Button>

              </Popconfirm>

            </div>
          );
        },

      }

    ];

    const defaultSorted = [{
      dataField: 'libelleNatRec',
      order: 'asc'
    }];



    return (
      <div>
        <Card title="Liste des Natures de Recette">

          <Media query="(min-width: 600px)" render={() =>
          (
            <ToolkitProvider
              keyField="id"
              data={natures}
              columns={columnsNatures}
              //cellEdit={cellEditFactory({ mode: 'dbclick' })}
              search
              //insertRow={true}
              exportCSV

            >
              {
                props => (
                  <div>
                    <Row >
                      <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                        {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                        <SearchBar {...props.searchProps}
                          //delay={ 1000 }
                          style={{ maxWidth: 750, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                          placeholder="Rechercher"

                        />
                      </Col>
                      {use_logiciel === 'NO' &&  //currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                        <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                          <button style={{ marginLeft: 3 }}
                            className="btn bg-primary text-light rounded"
                            onClick={this.handleAddOpen}>
                            NOUVEAU <i className="icon icon-add" />
                          </button>
                        </Col>

                      }
                    </Row>


                    <BootstrapTable
                      {...props.baseProps}
                      //bootstrap4
                      wrapperClasses="table-responsive"
                      //selectRow={ selectRow }
                      //defaultSorted={defaultSorted}
                      pagination={paginationFactory()}
                      defaultSorted={defaultSorted}
                      striped
                      hover
                      condensed
                      headerWrapperClasses="foo"
                      noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                    //filter={filterFactory()}
                    />

                  </div>

                )
              }
            </ToolkitProvider>
          )}
          />


          <Media query="(max-width: 599px)" render={() =>
          (

            <ToolkitProvider
              keyField="id"
              data={natures}
              columns={columnsNaturesPhone}
              //cellEdit={cellEditFactory({ mode: 'dbclick' })}
              search
              //insertRow={true}
              exportCSV

            >
              {
                props => (
                  <div>
                    <Row >
                      <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                        <i className="fa fa-search" style={{ margin: 10 }}></i>

                        <SearchBar {...props.searchProps}
                          //delay={ 1000 }
                          style={{ maxWidth: 450, width: '125%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                          placeholder="Rechercher"

                        />
                      </Col>
                      {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                        <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                          <button style={{ marginLeft: 3 }}
                            className="btn bg-primary text-light rounded"
                            onClick={this.handleAddOpen}>
                            NOUVEAU <i className="icon icon-add" />
                          </button>
                        </Col>
                      }

                    </Row>

                    <BootstrapTable
                      {...props.baseProps}
                      //bootstrap4
                      wrapperClasses="table-responsive"
                      //selectRow={ selectRow }
                      //defaultSorted={defaultSorted}
                      pagination={paginationFactory()}
                      defaultSorted={defaultSorted}
                      striped
                      hover
                      condensed
                      headerWrapperClasses="foo"
                      noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                    //filter={filterFactory()}
                    />

                  </div>

                )
              }
            </ToolkitProvider>
          )}
          />
        </Card>


        <Modal
          width={500}
          visible={isAddModalVisible}
          title="Ajouter une Nature de recette"
          //onOk={this.handleAddOpen}
          onCancel={this.handleCancelAdd}
          footer={[
            <Button  type="primary" loading={loading} onClick={this.handleAddNature}>
              Envoyer
            </Button>,
            <Button  type="danger" onClick={this.handleCancelAdd}>
              Annuler
            </Button>,

          ]}
        >
          <Form>
            <Row form>
             
              
              <Col md={12}>
                <FormGroup>
                  <Label for="libelleNatRec">Libellé</Label>
                  <Input type="text" name="libelleNatRec" value={libelleNatRec} onChange={this.handleChange} id="libelleNatRec" placeholder="" />
                </FormGroup>
              </Col>
            </Row>

          </Form>
        </Modal>

        <Modal
          width={500}
          visible={isUpdateModalVisible}
          title="Modifier la nature de recette"
          //onOk={this.handleAddOpen}
          onCancel={this.handleCancelUpdate}
          footer={[
            <Button  type="primary" loading={loading} onClick={this.handleUpdateNature}>
              Modifier
            </Button>,
            <Button  type="danger" onClick={this.handleCancelUpdate}>
              Annuler
            </Button>,


          ]}
        >
          <Form>
           {/*} <Row form>
            
              <Col md={6}>
                <FormGroup>
                  <Label for="codeNatRec">Codification</Label>
                  <Input disabled type="text" name="codeNatRec" value={codeNatRec} id="codeNatRec" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>
          */}

            <FormGroup>
              <Label for="libelleNatRec">Libellé</Label>
              <Input type="text" name="libelleNatRec" value={libelleNatRec} onChange={this.handleChange} id="libelleNatRec" placeholder="" />
            </FormGroup>

          </Form>
        </Modal>

        <Modal
          width={500}
          visible={isModalVisible}
          title="Détails Nature recette"
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          footer={[
            <Button  type="danger" onClick={this.handleCancel}>
              Retour
            </Button>,
          
          ]}
        >
          <Form>
             {/* <Row form>
          
              <Col md={12}>
                <FormGroup>
                  <Label for="codeNatRec">Code</Label>
                  <Input disabled type="text" name="codeNatRec" value={codeNatRec} id="codeNatRec" onChange={this.handleChange} placeholder="" />
                </FormGroup>
              </Col>
            </Row>*/}

            <FormGroup>
              <Label for="libelleNatRec">Libellé</Label>
              <Input disabled type="text" name="libelleNatRec" value={libelleNatRec} onChange={this.handleChange} id="libelleNatRec" placeholder="" />
            </FormGroup>

          </Form>
        </Modal>

      </div>
    )
  }
}


export default NatureRecette;
