import React from "react";
import { Card, Form, Input, Button, Modal, Select, message, Popconfirm, DatePicker, } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, FormGroup, Label, ListGroup, Table, Spinner } from 'reactstrap';
import Media from 'react-media';

//import filterFactory from 'react-bootstrap-table2-filter';
//import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import { services } from '../../../services/services'
import { use_logiciel } from '../../../env'
import { authenticationService } from "../../../services/authentication-service";



//const confirm = Modal.confirm;
const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;

const FormItem = Form.Item;

const moment = require('moment')

const currentUser = authenticationService.currentUserValue


class Sorties extends React.Component {
    state = {
        isModalVisible: false,
        isAddModalVisible: false,
        isUpdateModalVisible: false,
        loading: false,
        isListArticleVisible: false,
        isListArticleVisibleUpdate: false,

        isDetailsArticleVisible: false,
        isDetailsArticleVisibleUpdate: false,
        isValidationValidation: false,
        sorties: [],
        listeArticlesSelected: [],
        articles: [],
        articlesCodPro: [],
        formLayout: 'horizontal',

        usuel: "1",
        codTyp: "1",
        libCat: '',
        codeCat: '',
        emailFour: '',
        refFactFour: '',
        codAgence: '',
        modRegl: '',
        codmod: '',
        tauxAib: 0,
        fraisTransport: 0,
        cleRepartition: 0,
        dateRepartition: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        etatCmde: 'EN COURS',

        motifSortie: '',
        observation: '',
        codeMag: '',
        typeOpMag: '',
        beneficiaire: '',
        startDate: moment(),
        endDate: moment(),
        codAgenceChange: '',

        puAchat: '',
        puUtilise: '',

        isMagasin: false,

        waitingEndMsg: '',
        isLoading: true,
        loadingValidation: false,
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        const startDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')

        services.getSorties('', startDate, endDate)
            .then(sorties => {
                console.log('sorties', sorties)
                this.setState({ sorties })

                setTimeout(() => {

                    // //console.log("real articles", factures)
                    if (!sorties || !Array.isArray(sorties)
                        || !sorties.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucune Sortie actuellement !" })
                    }
                }, 500)
            })
        services.getAllAgences()
            .then(agences => {
                //console.log('agences', agences)
                this.setState({ agences })
            })

        services.getStocksAgence()
            .then(articles => {
                //console.log('articles', articles)
                this.setState({ articles })
            })
    }

    onDateChange = (date, dateString) => {
        // //console.log("DATE", date, dateString, dateString[0]);
        this.setState({ isLoading: true, sorties: [] })

        const startDate = moment(dateString[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(dateString[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
        //  //console.log("DATE CHANGE PERIODE", dateString[0], dateString[1], this.state.dateEnCours, this.state.codAgence, startDate, endDate)

        services.getSorties(this.state.codAgence, startDate, endDate)
            .then(sorties => {
                //console.log('sorties', sorties)
                this.setState({ sorties })

                setTimeout(() => {

                    // //console.log("real articles", sorties)
                    if (!sorties || !Array.isArray(sorties)
                        || !sorties.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune Sortie pour cette période!` })
                    }
                }, 500)
            })
        this.setState({ startDate: dateString[0], endDate: dateString[1] })

    }

    handleChangeAgenceFiltre = (value) => {

        const startDate = moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        this.setState({ codAgenceChange: value })
        this.setState({ isLoading: true, waitingEndMsg: '', sorties: [] })
        //console.log("AGENCE CHANGE", this.state.dateEnCours,value, startDate, endDate)
        services.getSorties(value, startDate, endDate)
            .then(sorties => {
                //  //console.log('sorties', sorties)
                this.setState({ sorties: sorties, isLoading: false, })

                setTimeout(() => {

                    // //console.log("real articles", sorties)
                    if (!sorties || !Array.isArray(sorties)
                        || !sorties.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: `Vous n'avez aucune Facture actuellement pour cette Agence` })
                    }
                }, 500)
            })

    }

    handleAddOpen = () => {
        this.setState({
            qtEntree: 0,
            qteStock: 0,
            qteStockEnCoursValidation: 0,
            codAgence: '',
            isAddModalVisible: !this.state.isAddModalVisible,
            articlesCodPro: [],
            listeArticlesSelected: [],
            loading: false,

            motifSortie: '',
            observation: '',
            codeMag: '',
            typeOpMag: '',
            beneficiaire: '',
        });
    };

    handleAddOpenMagasin = () => {
        this.setState({
            qtEntree: 0,
            qteStock: 0,
            qteStockEnCoursValidation: 0,
            codAgence: '',
            isAddModalVisible: !this.state.isAddModalVisible,
            articlesCodPro: [],
            listeArticlesSelected: [],
            loading: false,

            motifSortie: '',
            observation: '',
            codeMag: '',
            typeOpMag: '',
            beneficiaire: '',

            isMagasin: true,
        });
    };


    handleCancelAdd = () => {
        this.setState({
            isAddModalVisible: !this.state.isAddModalVisible,
            codCat: '',

            libCat: '',

        })
    }

    handleAddSortie = () => {
        const {

            motifSortie,
            observation,
            codeMag,
            typeOpMag,
            beneficiaire,

            // codAgence,

            listeArticlesSelected
        } = this.state


        if (motifSortie === '') {
            message.warning('Veuillez préciser un motif pour la sortie', 5)
            return;
        }

        if (!listeArticlesSelected.length > 0 || listeArticlesSelected === null) {
            message.warning('Vous devriez au moins sélectionner un article', 5)
            return;
        }

        this.setState({ loading: true })

        let req = {

            motifSortie,
            observation,
            codeMag,
            typeOpMag,
            beneficiaire,

            // codAgence,

            //montantAp: netaPayer,
            //netHT,
            //netaPayer,

            listeArticlesSelected,


        }


        ////console.log('REQ ADD APPROVISIONNEMENT', req)
        services.addSortie(req)
            .then(result => {
                //console.log(result)
                this.setState({
                    motifSortie: '',
                    observation: '',
                    codeMag: '',
                    typeOpMag: '',
                    beneficiaire: '',
                    // codAgence: '',
                    articlesCodPro: [],
                    listeArticlesSelected: [],
                    loading: false,

                    isAddModalVisible: !this.state.isAddModalVisible,

                })
                message.success('Sortie éffectuée avec succès', 10)

                const startDate = moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
                const endDate = moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')

                services.getSorties(this.state.codAgenceChange, startDate, endDate)
                    .then(sorties => {
                        this.setState({ sorties: sorties, waitingEndMsg: '' })
                    })

            })

            .catch(() => {
                message.error('Une erreur est survenue lors de l\'ajout, Veuillez réessayer plus tard', 10);
                this.setState({ loading: false })

            });
    }

    handleUpdateSortie = () => {
        const {
            codeSortie,
            motifSortie,
            observation,
            codeMag,
            typeOpMag,
            beneficiaire,
            //codAgence,

            listeArticlesSelected,

        } = this.state


        if (motifSortie === '') {
            message.warning('Veuillez préciser un motif pour la sortie', 5)
            return;
        }

        if (!listeArticlesSelected.length > 0 || listeArticlesSelected === null) {
            message.warning('Vous devriez au moins sélectionner un article', 5)
            return;
        }


        let req = {
            codeSortie,
            motifSortie,
            observation,
            codeMag,
            typeOpMag,
            beneficiaire,
            //codAgence,

            listeArticlesSelected,
        }

        services.updateRepartition(codeSortie, req)
            .then(result => {
                //console.log(result)
                this.setState({
                    codeSortie: '',
                    motifSortie: '',
                    observation: '',
                    codeMag: '',
                    typeOpMag: '',
                    beneficiaire: '',
                    codAgence: '',
                    articlesCodPro: [],
                    listeArticlesSelected: [],
                    isUpdateModalVisible: !this.state.isUpdateModalVisible,

                })
                message.success('Sortie modifier avec succès', 10)

                const startDate = moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
                const endDate = moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')

                services.getSorties(this.state.codAgenceChange, startDate, endDate)
                    .then(sorties => {
                        this.setState({ sorties })
                    })

            })

            .catch(() => {
                message.error('Une erreur est survenue lors de la modification, Veuillez réessayer plus tard', 10)
            });
    }

    handleDelete = (row) => {
        this.setState({
            codeSortie: row.codeSortie,
            codAgence: row.codAgence
        })

    }

    handleDeleteSortie = () => {

        const { codeSortie, codAgence } = this.state

        let req = {
            codeSortie,
            codAgence,
        }

        services.deleteSortie(req)
            .then(del => {
                ////console.log('delete user', del)
                message.success('Sortie supprimer avec succès', 10)

                const startDate = moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
                const endDate = moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')

                services.getSorties(this.state.codAgenceChange, startDate, endDate)
                    .then(sorties => {
                        this.setState({ sorties })

                        setTimeout(() => {

                            // //console.log("real articles", factures)
                            if (!sorties || !Array.isArray(sorties)
                                || !sorties.length > 0) {
                                this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucune Sortie en Cours actuellement !" })
                            }
                        }, 500)
                    })

            })
            .catch((err) => {
                message.error(err, 10)
            });

    }

    //SUPP
    handleValider = (row) => {
        this.setState({
            codeSortie: row.codeSortie,
            isValidationValidation: !this.state.isValidationValidation
        })

    }
    handleCloseValidation = () => {
        this.setState({ isValidationValidation: !this.state.isValidationValidation, codeEntre: '' })
    }

    handleValiderRepartition = () => {

        const { codeSortie } = this.state
        this.setState({ loadingValidation: true, loading: true })


        services.validerRepartition(codeSortie)
            .then(del => {
                ////console.log('delete user', del)
                message.success('Sortie Valider avec succès', 10)
                this.setState({
                    isValidationValidation: !this.state.isValidationValidation,
                    loading: false,
                })
                services.getSorties()
                    .then(sorties => {
                        this.setState({ sorties })
                        setTimeout(() => {

                            // //console.log("real articles", factures)
                            if (!sorties || !Array.isArray(sorties)
                                || !sorties.length > 0) {
                                this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucune Sortie en Cours actuellement !" })
                            }
                        }, 500)
                    })

            })
            .catch(() => {
                message.error('Une erreur est survenue lors de la validation, Veuillez réessayer plus tard', 10)
                this.setState({ loading: false })
            });

    }

    handleUpdateOpen = (row) => {
        this.setState({
            isUpdateModalVisible: !this.state.isUpdateModalVisible,
            codeSortie: row.codeSortie,
            dateRepartition: row.dateRepartition,
            observation: row.observation,
            codAgence: row.codAgence,
            //codAgence: `${row.codAgence}`,
            listeArticlesSelected: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return {
                        qtEntree: details.qtEntree,
                        qteStock: details && details.stock ? details.stock.qteStock : '',
                        qteStockEnCoursValidation: details && details.stock ? details.stock.qteStockEnCoursValidation : '',
                        designation: details && details.article ? details.article.designation : '',
                        codProd: details && details.article ? details.article.codPro : '',

                        puAchat: details && details.article ? details.article.puAchat : '',
                        puUtilise: details && details.article ? details.article.puUtilise : '',

                    }
                }) : [],

            articlesCodPro: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return details && details.article ? details.article.codPro : ''
                }) : [],

        })

    }

    handleCancelUpdate = () => {
        this.setState({
            isUpdateModalVisible: !this.state.isUpdateModalVisible,
            dateRepartition: '',
            observation: '',
            codAgence: '',

            listeArticlesSelected: [],
            loading: false,


        })
    }

    //// FIN SUPP

    handleView = (row) => {
        //console.log('DETALS', row)
        this.setState({
            isModalVisible: !this.state.isModalVisible,
            motifSortie: row.motifSortie,
            observation: row.observation,
            codeMag: row.codeMag,
            typeOpMag: row.typeOpMag,
            beneficiaire: row.beneficiaire,

            codAgence: row.codAgence,

            listeArticlesSelected: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return {
                        //qtEntree: details.qtEntree,
                        //qteStock: details && details.stock ? details.stock.qteStock : '',
                        qteSortie: details ? parseFloat(details.qteSortie) : '',
                        //qteStockEnCoursValidation: details && details.stock ? details.stock.qteStockEnCoursValidation : '',
                        designation: details && details.article ? details.article.designation : '',
                        codProd: details && details.article ? details.article.codPro : '',

                        //puAchat: details && details.article ? details.article.puAchat : '',
                        //puUtilise: details && details.article ? details.article.puUtilise : '',

                    }
                }) : [],

            articlesCodPro: row.listeArticlesSelected && row.listeArticlesSelected.length ?
                row.listeArticlesSelected.map((details, k) => {
                    return details && details.article ? details.article.codPro : ''
                }) : [],

        })
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            motifSortie: '',
            observation: '',
            codeMag: '',
            typeOpMag: '',
            beneficiaire: '',
            codAgence: '',

            listeArticlesSelected: [],
        });
    }


    //agences
    handleCancelAddAgence = () => {
        this.setState({
            isAddAgenceModalVisible: !this.state.isAddAgenceModalVisible,
            codAgence: '',
            codificationAgence: '',
            nomAgence: '',
            adrAgence: '',
            telAgence: '',
            nomGerant: '',
            telGerant: '',
            observation: '',
        })
    }


    handleAddAgence = () => {
        const {
            //codAgence,
            codificationAgence,
            nomAgence,
            adrAgence,
            telAgence,
            nomGerant,
            telGerant,
            observation,
        } = this.state


        if (nomAgence === '') {
            message.warning('Veuillez préciser le nom de l\'Agence', 5)
            return;
        }
        /*if (codAgence === '') {
          message.warning('Veuillez sélectionner une Agence', 5)
          return;
        }*/

        let req = {
            //codAgence,
            codificationAgence,
            nomAgence: nomAgence.toUpperCase(),
            adrAgence,
            telAgence,
            nomGerant,
            telGerant,
            observation,
            //raisonSociale: raisonSociale.toUpperCase(),

        }
        services.addAgence(req)
            .then(result => {
                //console.log(result)
                this.setState({
                    codAgence: '',
                    codificationAgence: '',
                    nomAgence: '',
                    adrAgence: '',
                    telAgence: '',
                    nomGerant: '',
                    telGerant: '',
                    observation: '',
                    isAddAgenceModalVisible: !this.state.isAddAgenceModalVisible,

                })
                message.success('Agences ajouter avec succès', 10)

                services.getAllAgences()
                    .then(agences => {
                        this.setState({ agences })
                    })

            })

            .catch((err) => {
                message.error(err, 10)
            });
    }

    //FIN FOURNISSEUR


    handleChangeAgence = (value) => {
        this.setState({ codAgence: value })
    }


    openListArticleModal = () => {
        services.getStocksAgence()
            .then(articles => {
                ////console.log('ARTICLES', articles)

                this.setState({
                    articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codProd)),
                })
            })
        this.setState({ isListArticleVisible: !this.state.isListArticleVisible })
    }

    openListArticleModalUpdate = () => {
        services.getStocksAgence()
            .then(articles => {
                ////console.log('ARTICLES', articles)

                this.setState({
                    articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codProd)),
                })
            })
        this.setState({ isListArticleVisibleUpdate: !this.state.isListArticleVisibleUpdate })
    }


    onRechercheArticleChange = (e) => {
        services.getStocksAgence(e.target.value.toUpperCase())
            .then(articles => {
                //console.log('ARTICLES', articles)

                this.setState({
                    articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codProd)),
                })
            })
    }

    handleArticleSelected = () => {

        const {
            qteStock,

            qtEntree,
           // puUtilise,
            //puAchat,

            qteStockEnCoursValidation,
            //puEntree,
            //codTva,
            article,
            articles,
        } = this.state

        if (qtEntree === '') {
            message.warning('Veuillez préciser la Quantité à Répartir', 6)
            return;
        }
        if (qtEntree > (parseFloat(qteStock) - parseFloat(qteStockEnCoursValidation))) {
            message.warning('La quantité entrée est supérieure à la quantité disponible, Veuillez éffectuer un Approvisionnement d\'abord', 6)
            return;
        }

        const articleCod = article.codProd

        /*let tauxTva =
            (this.state.codTva === "A" || this.state.codTva === "C" || this.state.codTva === "E" || this.state.codTva === "F") &&
            0
            ||
            (this.state.codTva === "B" || this.state.codTva === "D") &&
            18;
*/

        this.setState({
            listeArticlesSelected: [
                ...this.state.listeArticlesSelected,
                {
                    qtEntree: qtEntree,
                    qteSortie: qtEntree,
                    puAchat: article.article ? article.article.puAchat : 0,
                    puUtilise: article.article ? article.article.puUtilise : 0,
                    qteStock: qteStock,
                    qteStockEnCoursValidation: qteStockEnCoursValidation,
                    //puEntree: puEntree,
                    //codTva: codTva,
                    //tauxTva: tauxTva,
                    codProd: article.codProd,
                    designation: article.article ? article.article.designation : ''

                }
            ],

        })
        this.setState(() => ({
            articlesCodPro: [...this.state.articlesCodPro, articleCod],
            articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codProd) && x.codProd !== articleCod),
            isDetailsArticleVisible: !this.state.isDetailsArticleVisible,
            isListArticleVisible: !this.state.isListArticleVisible,
            qtEntree: '',
        }))
        // //console.log('ArticleCod & articles', this.state.articlesCodPro, articles)
    }

    handleArticleSelectedUpdate = () => {

        const {
            qteStock,
            qtEntree,
            qteStockEnCoursValidation,
            //puEntree,
            //codTva,

           // puAchat,
            //puUtilise,

            article,
            articles,
        } = this.state

        if (qtEntree === '') {
            message.warning('Veuillez préciser la Quantité à Répartir', 6)
            return;
        }
        if (qtEntree > (parseFloat(qteStock) - parseFloat(qteStockEnCoursValidation))) {
            message.warning('La quantité entrée est supérieure à la quantité disponible, Veuillez éffectuer un Approvisionnement d\'abord', 6)
            return;
        }

        const articleCod = article.codProd

        /*let tauxTva =
            (this.state.codTva === "A" || this.state.codTva === "C" || this.state.codTva === "E" || this.state.codTva === "F") &&
            0
            ||
            (this.state.codTva === "B" || this.state.codTva === "D") &&
            18;
*/

        this.setState({
            listeArticlesSelected: [
                ...this.state.listeArticlesSelected,
                {
                    qtEntree: qtEntree,
                    qteStock: qteStock,
                    qteStockEnCoursValidation: qteStockEnCoursValidation,

                    puUtilise: article.article ? article.article.puUtilise : 0,
                    puAchat: article.article ? article.article.puAchat : 0,
                    //puEntree: puEntree,
                    //codTva: codTva,
                    //tauxTva: tauxTva,
                    codProd: article.codProd,
                    designation: article.article ? article.article.designation : ''

                }
            ],

        })
        this.setState(() => ({
            articlesCodPro: [...this.state.articlesCodPro, articleCod],
            articles: articles.filter(x => !this.state.articlesCodPro.some(articleCod => articleCod === x.codProd) && x.codProd !== articleCod),
            isDetailsArticleVisibleUpdate: !this.state.isDetailsArticleVisibleUpdate,
            isListArticleVisibleUpdate: !this.state.isListArticleVisibleUpdate,
            qtEntree: '',
        }))
        // //console.log('ArticleCod & articles', this.state.articlesCodPro, articles)
    }


    handleDeleteArticlesSelected = (article) => {

        const { listeArticlesSelected, articlesCodPro } = this.state
        //console.log('ID Art', article, listeArticlesSelected)

        this.setState({
            listeArticlesSelected: [...listeArticlesSelected.filter(x => x.codProd !== article.codProd)],
            articlesCodPro: [...articlesCodPro.filter(x => x !== article.codProd)]
        })


    }

    openDetailArticleModal = (article) => {
        ////console.log('article', article)
        this.setState({
            article: article,
            qteStock: article.qteStock,
            puUtilise: article.article ? article.article.puUtilise : 0,
            puAchat: article.article ? article.article.puAchat : 0,
            qteStockEnCoursValidation: article.qteStockEnCoursValidation,
            designation: article ? article.article.designation : '',
            isDetailsArticleVisible: !this.state.isDetailsArticleVisible,
            //qtEntree: '',
            //puEntree: '',
            //codTva: '',
        })
    }

    openDetailArticleModalUpdate = (article) => {
        ////console.log('article', article)
        this.setState({
            article: article,
            qteStock: article.qteStock,
            qteStockEnCoursValidation: article.qteStockEnCoursValidation,
            designation: article ? article.article.designation : '',
            isDetailsArticleVisibleUpdate: !this.state.isDetailsArticleVisibleUpdate,

            puAchat: article ? article.article.puAchat : '',
            puUtilise: article ? article.article.puUtilise : '',
            //qtEntree: '',
            //puEntree: '',
            //codTva: '',
        })
    }

    closeDetailArticleModal = () => {
        this.setState({ isDetailsArticleVisible: false })
    }

    closeDetailArticleModalUpdate = () => {
        this.setState({ isDetailsArticleVisibleUpdate: false })
    }


    render() {
        const {
            isModalVisible,
            loading,

            observation,
            codAgence,
            motifSortie,
            //codeMag,
            //typeOpMag,
            beneficiaire,
            endDate,
            startDate,
            codAgenceChange,
            isMagasin,



           // etatCmde,

            agences,
            listeArticlesSelected,
            articles,

            sorties,
            qteStockEnCoursValidation,



            isAddAgenceModalVisible,
            isAddModalVisible,
            isUpdateModalVisible,
            isListArticleVisible,
            isListArticleVisibleUpdate,
            isDetailsArticleVisible,
            isDetailsArticleVisibleUpdate,
            isValidationValidation,

            qtEntree,

            codificationAgence,
            nomAgence,
            adrAgence,
            telAgence,
            nomGerant,
            telGerant,

            qteStock,

            designation,
            isLoading,
            waitingEndMsg,
        } = this.state

        //console.log('listeArticlesSelected', listeArticlesSelected)

        const titleDetailsArticle = `Complèter les détails pour l'Article ${designation}`

        const nbresArticles = listeArticlesSelected && listeArticlesSelected.length ?
            listeArticlesSelected.reduce(function (prev, cur) {
                return prev + parseFloat(cur.qteSortie)
            }, 0) : 0


        const InputGroup = Input.Group;
        const Option = Select.Option;

        const { SearchBar } = Search;

        /*const formItemLayout = formLayout === 'horizontal' ? {
            labelCol: { xs: 24, sm: 6 },
            wrapperCol: { xs: 24, sm: 14 },
        } : null;*/

        const columnsSorties = [
            {
                dataField: 'codeSortie',
                text: ' Code'
            },
            {
                dataField: 'dateSortie',
                text: ' Date',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div className="text-center">
                            {
                                moment(new Date(row.dateSortie), 'YYYY-MM-DD').format('DD/MM/YYYY')
                            }
                        </div>
                    );
                },
            },
            {
                dataField: 'motifSortie',
                text: 'Motif'
            },
            {
                dataField: 'beneficiaire',
                text: 'Bénéficiaire'
            },
            {
                dataField: 'observation',
                text: 'Observation'
            },
            {
                dataField: 'qui',
                text: 'Auteur'
            },

            {
                dataField: 'agences.nomAgence',
                text: 'Agences'
            },

            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {//*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'VALIDER' &&
                                <Button
                                    title="Visualiser"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleView(row)}
                                >
                                    <i className="icon icon-custom-view" />
                                </Button>
                            }
                            {//*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                                /* <Button
                                     hidden={use_logiciel === 'NO' ? false : true}
                                     title="Modifier"
                                     type="primary"
                                     //fullWidth
                                     variant="contained"
                                     color="danger"
                                     size="small"
                                     //className="btn btn-success btn-sm"
                                     onClick={() => this.handleUpdateOpen(row)}
                                 >
                                     <i className="icon icon-edit" />
                                 </Button>*/
                            }
                            {//*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                                /* <button
                                     hidden={use_logiciel === 'NO' ? false : true}
                                     title="Valider"
                                     type="danger"
                                     //fullWidth
                                     variant="contained"
                                     color="primary"
                                     className="btn bg-success text-light rounded btn-lg"
                                     //className="gx-btn-block gx-btn-yellow"
                                     size="small"
                                     onClick={() => this.handleValider(row)}
                                 >
                                     <i className="icon icon-check-circle-o" />
                                 </button>*/
                            }
                            {//*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                                <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cette Sortie??" onConfirm={this.handleDeleteSortie} onCancel={this.cancelDelete} okText="Oui"
                                    cancelText="Non">
                                    <Button
                                        title="Suppprimer"
                                        type="danger"
                                        //fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        //className="btn btn-success btn-sm"
                                        onClick={() => this.handleDelete(row)}
                                    >
                                        <i className="icon icon-alert-new" />
                                    </Button>

                                </Popconfirm>
                            }

                        </div>
                    );
                },

            }

        ];


        const columnsSortiesPhone = [

            {
                dataField: 'codeSortie',
                text: ' Code'
            },
            {
                dataField: 'dateSortie',
                text: ' Date'
            },
            {
                dataField: 'motifSortie',
                text: 'Motif'
            },

            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {//*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'VALIDER' &&
                                <Button
                                    title="Visualiser"
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleView(row)}
                                >
                                    <i className="icon icon-custom-view" />
                                </Button>
                            }
                            {//*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                                /* <Button
                                     hidden={use_logiciel === 'NO' ? false : true}
                                     title="Modifier"
                                     type="primary"
                                     //fullWidth
                                     variant="contained"
                                     color="danger"
                                     size="small"
                                     //className="btn btn-success btn-sm"
                                     onClick={() => this.handleUpdateOpen(row)}
                                 >
                                     <i className="icon icon-edit" />
                                 </Button>*/
                            }
                            {//*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                                /* <button
                                     hidden={use_logiciel === 'NO' ? false : true}
                                     title="Valider"
                                     type="danger"
                                     //fullWidth
                                     variant="contained"
                                     color="primary"
                                     className="btn bg-success text-light rounded btn-lg"
                                     //className="gx-btn-block gx-btn-yellow"
                                     size="small"
                                     onClick={() => this.handleValider(row)}
                                 >
                                     <i className="icon icon-check-circle-o" />
                                 </button>*/
                            }
                            {//*currentUser.slug === 'COMMERCIAL_ROLE' &&*/ row.etatCmde === 'EN COURS' &&
                                <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cette Sortie??" onConfirm={this.handleDeleteSortie} onCancel={this.cancelDelete} okText="Oui"
                                    cancelText="Non">
                                    <Button
                                        title="Suppprimer"
                                        type="danger"
                                        //fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        //className="btn btn-success btn-sm"
                                        onClick={() => this.handleDelete(row)}
                                    >
                                        <i className="icon icon-alert-new" />
                                    </Button>

                                </Popconfirm>
                            }

                        </div>
                    );
                },

            }

        ];

        const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
        }];


        return (
            <div>
                {(currentUser && currentUser.slug === "ADMIN_ROLE" /*&& currentUser.agence.enCours === true*/) ?
                    <Card title="Liste des Sorties">

                        <Media query="(min-width: 600px)" render={() =>
                        (
                            <ToolkitProvider
                                keyField="id"
                                data={sorties}
                                columns={columnsSorties}
                                //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                                search
                                //insertRow={true}
                                exportCSV

                            >
                                {
                                    props => (
                                        <div>
                                            <Row >
                                                <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                    {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                                    <SearchBar {...props.searchProps}
                                                        //delay={ 1000 }
                                                        style={{ maxWidth: 750, width: '160%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                        placeholder="Rechercher"

                                                    />
                                                </Col>

                                                <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                    {use_logiciel === 'NO' &&  //currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                                                        <button style={{ marginLeft: 1 }}
                                                            className="btn bg-primary text-light rounded"
                                                            onClick={this.handleAddOpen}>
                                                            NOUVEAU <i className="icon icon-add" />
                                                        </button>
                                                    }
                                                    {use_logiciel === 'NO' &&  //currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                                                        <button disabled style={{ marginLeft: 1 }}
                                                            className="btn bg-success text-light rounded"
                                                            onClick={this.handleAddOpenMagasin}>
                                                            Transfert Magasin <i className="icon icon-forward-o" />
                                                        </button>
                                                    }
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>


                                                    <FormGroup className="mr-2">
                                                        <RangePicker className="gx-w-100"
                                                            defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                            format={dateFormat}
                                                            onChange={this.onDateChange}
                                                            ranges={{
                                                                "Aujourd'hui": [moment(), moment()],
                                                                'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                                'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                                'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                            }}
                                                        />
                                                        <h6 className="mr-3" style={{ color: "black", }}><span>Filtrer par Période</span></h6>
                                                    </FormGroup>

                                                    {(currentUser && currentUser.agence && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") &&
                                                        <FormGroup >
                                                            <Select

                                                                value={codAgenceChange}
                                                                showSearch
                                                                //style={{width: 200}}
                                                                style={{ width: '100%' }}
                                                                placeholder="Filtrer par Agence"
                                                                optionFilterProp="children"
                                                                onChange={this.handleChangeAgenceFiltre}
                                                                onFocus={this.handleFocus}
                                                                onBlur={this.handleBlur}
                                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                            >
                                                                <Option value=''>TOUTES</Option>
                                                                {
                                                                    agences && agences.length ?
                                                                        agences.map((agence, k) => {
                                                                            return <Option value={agence.codAgence}>{agence.nomAgence}</Option>
                                                                        }) : null
                                                                }

                                                            </Select>
                                                            <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                                        </FormGroup>}



                                                </Col>


                                            </Row>

                                            {
                                                !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                            }
                                            {
                                                sorties &&
                                                    Array.isArray(sorties) &&
                                                    sorties.length > 0 ?

                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        //bootstrap4
                                                        wrapperClasses="table-responsive"
                                                        //selectRow={ selectRow }
                                                        //defaultSorted={defaultSorted}
                                                        pagination={paginationFactory()}
                                                        defaultSorted={defaultSorted}
                                                        striped
                                                        hover
                                                        condensed
                                                        headerWrapperClasses="foo"
                                                    //noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                                    //filter={filterFactory()}
                                                    />
                                                    :
                                                    isLoading ?
                                                        <div style={{ justifyContent: 'center' }}>

                                                            <Row style={{ justifyContent: 'center' }}>
                                                                <Col style={{ justifyContent: 'center' }}>
                                                                    <Spinner type="grow" color="primary" />
                                                                    <Spinner type="grow" color="secondary" />
                                                                    <Spinner type="grow" color="success" />
                                                                    <Spinner type="grow" color="danger" />
                                                                    <Spinner type="grow" color="warning" />
                                                                    <Spinner type="grow" color="info" />
                                                                    <Spinner type="grow" color="light" />
                                                                    <Spinner type="grow" color="dark" />
                                                                </Col>
                                                            </Row> <br />
                                                        </div>
                                                        : null
                                            }

                                        </div>

                                    )
                                }
                            </ToolkitProvider>
                        )}
                        />


                        <Media query="(max-width: 599px)" render={() =>
                        (

                            <ToolkitProvider
                                keyField="id"
                                data={sorties}
                                columns={columnsSortiesPhone}
                                //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                                search
                                //insertRow={true}
                                exportCSV

                            >
                                {
                                    props => (
                                        <div>
                                            <Row >
                                                <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>

                                                    <SearchBar {...props.searchProps}
                                                        //delay={ 1000 }
                                                        style={{ maxWidth: 450, width: '125%', display: 'flex' }}
                                                        placeholder="Rechercher"

                                                    />
                                                </Col>
                                                {/* <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>
                                                  <FormGroup>
                                                        <Select
                                                            //style={{ maxWidth: 650, width: '90%', margin: 'auto', display: 'flex', borderRadius: 50 }}

                                                            value={etatCmde}
                                                            showSearch
                                                            //style={{width: 200}}
                                                            style={{ width: '90%' }}
                                                            placeholder="Filtrer par statut"
                                                            optionFilterProp="children"
                                                            onChange={this.handleChangeEtatCmde}
                                                            onFocus={this.handleFocus}
                                                            onBlur={this.handleBlur}
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >

                                                            <Option value="EN COURS">EN COURS</Option>
                                                            <Option value="VALIDER">VALIDER</Option>

                                                        </Select>
                                                        <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Status</span></h6>
                                                    </FormGroup>

                                                </Col>*/}
                                                {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&

                                                    <Col className="mt-2" md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                        <button style={{ marginLeft: 3 }}
                                                            className="btn bg-primary text-light rounded"
                                                            onClick={this.handleAddOpen}>
                                                            NOUVEAU <i className="icon icon-add" />
                                                        </button>
                                                    </Col>
                                                }

                                            </Row>

                                            <BootstrapTable
                                                {...props.baseProps}
                                                //bootstrap4
                                                wrapperClasses="table-responsive"
                                                //selectRow={ selectRow }
                                                //defaultSorted={defaultSorted}
                                                pagination={paginationFactory()}
                                                defaultSorted={defaultSorted}
                                                striped
                                                hover
                                                condensed
                                                headerWrapperClasses="foo"
                                                noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"

                                            //filter={filterFactory()}
                                            />

                                        </div>

                                    )
                                }
                            </ToolkitProvider>
                        )}
                        />
                    </Card>
                    :
                    <Row>
                        <Col style={{ display: "flex", justifyContent: "center" }}>
                            <h3 style={{ color: "red" }}>Vous n'avez pas le droit d'Accéder à cette page</h3>

                        </Col>
                    </Row>
                }



                <Modal
                    width={600}
                    visible={isListArticleVisible}
                    title="Liste Produits"
                    //onOk={this.handleAddOpen}
                    onCancel={this.openListArticleModal}
                    footer={[
                        /* <Button type="primary" loading={loading} onClick={this.handleUpdateSortie}>
                             Enrégistrer
                         </Button>,*/
                        <Button type="danger" onClick={this.openListArticleModal}>
                            Fermer
                        </Button>,

                    ]}
                >
                    <Row>
                        <Col md={12}>
                            <Input onChange={this.onRechercheArticleChange} type='text' placeholder='Rechercher' style={{ width: '100%' }} /> <hr />
                        </Col>
                    </Row>
                    <Row style={{ height: '300px', overflow: 'auto' }}>

                        <Col md={12}>
                            <ListGroup>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>
                                            <th className="text-center">Articles</th>
                                            <th className="text-center">Stock Actuel</th>
                                            <th className="text-center">Stock En Validation</th>
                                            <th className="text-center">Stock Restant</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            articles && articles.length ?
                                                articles.map((article, k) => {
                                                    return article.article && article.article.designation &&
                                                        <tr key={k} id={k} onClick={(e) => this.openDetailArticleModal(article)} style={{ cursor: "pointer" }}>
                                                            <td className="text-center">{article.article ? article.article.designation : ''}</td>
                                                            <td className="text-center">{article.qteStock}</td>
                                                            <td className="text-center">{article.qteStockEnCoursValidation}</td>
                                                            <td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qteStockEnCoursValidation)}</td>

                                                        </tr>
                                                }) : null
                                        }

                                    </tbody>
                                </Table>
                            </ListGroup>
                        </Col>
                    </Row>
                </Modal>

                <Modal
                    width={600}
                    visible={isListArticleVisibleUpdate}
                    title="Liste Produits"
                    //onOk={this.handleAddOpen}
                    onCancel={this.openListArticleModalUpdate}
                    footer={[
                        /* <Button type="primary" loading={loading} onClick={this.handleUpdateSortie}>
                             Enrégistrer
                         </Button>,*/
                        <Button type="danger" onClick={this.openListArticleModalUpdate}>
                            Fermer
                        </Button>,

                    ]}
                >
                    <Row>
                        <Col md={12}>
                            <Input onChange={this.onRechercheArticleChange} type='text' placeholder='Rechercher' style={{ width: '100%' }} /> <hr />
                        </Col>
                    </Row>
                    <Row style={{ height: '300px', overflow: 'auto' }}>

                        <Col md={12}>
                            <ListGroup>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>
                                            <th className="text-center">Produits</th>
                                            <th className="text-center">Stock Actuel</th>
                                            <th className="text-center">Stock En Validation</th>
                                            <th className="text-center">Stock Restant</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            articles && articles.length ?
                                                articles.map((article, k) => {
                                                    return article.article && article.article.designation &&
                                                        <tr key={k} id={k} onClick={(e) => this.openDetailArticleModalUpdate(article)} style={{ cursor: "pointer" }}>
                                                            <td className="text-center">{article.article ? article.article.designation : ''}</td>
                                                            <td className="text-center">{article.qteStock}</td>
                                                            <td className="text-center">{article.qteStockEnCoursValidation}</td>
                                                            <td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qteStockEnCoursValidation)}</td>

                                                        </tr>
                                                }) : null
                                        }

                                    </tbody>
                                </Table>

                            </ListGroup>

                        </Col>


                    </Row>
                </Modal>


                <Modal
                    width={800}
                    visible={isAddModalVisible}
                    title={isMagasin? "Transfert vers Magasin" : "Effectuer une Sortie"}
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelAdd}
                    footer={[
                        <Button type="primary" loading={loading} onClick={this.handleAddSortie}>
                            Enrégistrer
                        </Button>,
                        <Button type="danger" onClick={this.handleCancelAdd}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        {/*<Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codAgence">Agence</Label>
                                    <InputGroup compact>

                                        <Select
                                            value={codAgence}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '75%' }}
                                            placeholder="Selectionner une Agence"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeAgence}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                agences && agences.length ?
                                                    agences.map((agence, k) => {
                                                        return <Option key={k} value={agence.codAgence}>{agence.nomAgence}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button onClick={this.handleCancelAddAgence} style={{ width: '25%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                                        */}

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="beneficiaire">Bénéficiare</Label>
                                    <Input type="text" name="beneficiaire" value={beneficiaire} id="beneficiaire" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="motifSortie">Motif</Label>
                                    <Input type="text" name="motifSortie" value={motifSortie} id="motifSortie" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <button className="btn waves-effect waves-light btn-rounded btn-primary ml-auto" onClick={this.openListArticleModal}><i className="icon icon-add" /> Ajouter Produit</button>
                            </Col>
                            <Col md={6} >

                                <FormItem label="Nbre d'Articles">
                                    <Input disabled type="number" name="nbresArticles" value={nbresArticles} id="nbresArticles" onChange={this.handleChange} />
                                </FormItem>

                            </Col>
                            <Col md={12}>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>

                                            <th className="text-center">Code</th>
                                            <th className="text-center">Désignation</th>
                                            <th className="text-center">Qté à Sortir</th>
                                            <th className="text-center">Qté Potentiel Stock</th>
                                            <th className="text-center">Qté Potentiel Restant</th>
                                            <th className="text-center">Retirer</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listeArticlesSelected && listeArticlesSelected.length ?
                                                listeArticlesSelected.map((article, k) => {
                                                    //const puRevient = parseFloat(cleRepartition + article.puEntree)
                                                    return (
                                                        <tr key={k}>

                                                            <td>{article.codProd}</td>
                                                            <td className="text-center">{article.designation}</td>
                                                            <td className="text-center">{article.qtEntree}</td>
                                                            <td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qteStockEnCoursValidation)}</td>

                                                            <td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qtEntree) - parseFloat(article.qteStockEnCoursValidation)}</td>

                                                            <Button className="text-center" type="danger" onClick={() => this.handleDeleteArticlesSelected(article)}>
                                                                <i className="icon icon-close-circle" />
                                                            </Button>

                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        {/*<Row className="mt-3">
                            <Col md={12}>
                                <Card title="Totaux et Taxes" className="gx-card">
                                    <FormItem label="Net HT"  {...formItemLayout}>

                                        <Input disabled type="number" name="netHT" id="netHT" value={netHT} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                    <FormItem label="Net à Payer"  {...formItemLayout}>
                                        <Input disabled type="number" name="netaPayer" id="netaPayer" value={netaPayer} onChange={this.handleChange} placeholder="" />
                                    </FormItem>
                                </Card>
                            </Col>
                                    </Row>*/}
                    </Form>
                </Modal>

                <Modal
                    width={900}
                    visible={isUpdateModalVisible}
                    title="Modifier la Sortie"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelUpdate}
                    footer={[
                        <Button type="primary" loading={loading} onClick={this.handleUpdateSortie}>
                            Modifier
                        </Button>,
                        <Button type="danger" onClick={this.handleCancelUpdate}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codAgence">Agence</Label>
                                    <InputGroup compact>

                                        <Select
                                            value={codAgence}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '75%' }}
                                            placeholder="Selectionner une Agence"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeAgence}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                agences && agences.length ?
                                                    agences.map((agence, k) => {
                                                        return <Option key={k} value={agence.codAgence}>{agence.nomAgence}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button onClick={this.handleCancelAddAgence} style={{ width: '25%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        {/* <Row form>
                            
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="montantAp">Montant Commande</Label>
                                    <Input disabled type="number" name="montantAp" value={montantAp} id="montantAp" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                       </Row>*/}

                        <Row>
                            <Col md={8}>
                                <button className="btn waves-effect waves-light btn-rounded btn-primary ml-auto" onClick={this.openListArticleModalUpdate}><i className="icon icon-add" /> Ajouter Produit</button>
                            </Col>
                            <Col md={4} >

                                <FormItem label="Nbre d'Articles">
                                    <Input disabled type="number" name="nbresArticles" value={nbresArticles} id="nbresArticles" onChange={this.handleChange} />
                                </FormItem>

                            </Col>
                            <Col md={12}>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>

                                            <th className="text-center">Code</th>
                                            <th className="text-center">Désignation</th>
                                            <th className="text-center">Qté à Sortir</th>
                                            {/*<th className="text-center">Qté Potentiel Stock</th>
                                            <th className="text-center">Qté Potentiel Restant</th>*/}
                                            <th className="text-center">Retirer</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listeArticlesSelected && listeArticlesSelected.length ?
                                                listeArticlesSelected.map((article, k) => {
                                                    //const puRevient = parseFloat(cleRepartition + article.puEntree)
                                                    return (
                                                        <tr key={k}>

                                                            <td>{article.codProd}</td>
                                                            <td className="text-center">{article.designation}</td>
                                                            <td className="text-center">{article.qtEntree}</td>
                                                            {/*<td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qteStockEnCoursValidation) + parseFloat(article.qtEntree)}</td>

                                                    <td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qteStockEnCoursValidation)}</td>*/}

                                                            <Button className="text-center" type="danger" onClick={() => this.handleDeleteArticlesSelected(article)}>
                                                                <i className="icon icon-close-circle" />
                                                            </Button>

                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </Form>
                </Modal>

                <Modal
                    width={900}
                    visible={isModalVisible}
                    title="Détails Sortie"
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" type="danger" onClick={this.handleCancel}>
                            Retour
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="codAgence">Agence</Label>
                                    <InputGroup compact>

                                        <Select
                                            disabled
                                            value={codAgence}
                                            showSearch
                                            //style={{width: 200}}
                                            style={{ width: '75%' }}
                                            placeholder="Selectionner une Agence"
                                            optionFilterProp="children"
                                            onChange={this.handleChangeAgence}
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                agences && agences.length ?
                                                    agences.map((agence, k) => {
                                                        return <Option key={k} value={agence.codAgence}>{agence.nomAgence}</Option>
                                                    }) : null
                                            }

                                        </Select>
                                        <Button disabled onClick={this.handleCancelAddAgence} style={{ width: '25%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                                    </InputGroup>
                                </FormGroup>

                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="observation">Observation</Label>
                                    <Input disabled type="text" name="observation" id="observation" value={observation} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="beneficiaire">Bénéficiare</Label>
                                    <Input disabled type="text" name="beneficiaire" value={beneficiaire} id="beneficiaire" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="motifSortie">Motif</Label>
                                    <Input disabled type="text" name="motifSortie" value={motifSortie} id="motifSortie" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                        </Row>

                        {/* <Row form>
                            
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="montantAp">Montant Commande</Label>
                                    <Input disabled type="number" name="montantAp" value={montantAp} id="montantAp" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                       </Row>*/}

                        <Row>
                            <Col md={8}>
                                <button disabled className="btn waves-effect waves-light btn-rounded btn-primary ml-auto" onClick={this.openListArticleModal}><i className="icon icon-add" /> Ajouter Produit</button>
                            </Col>
                            <Col md={4} >

                                <FormItem label="Nbre d'Articles">
                                    <Input disabled type="number" name="nbresArticles" value={nbresArticles} id="nbresArticles" onChange={this.handleChange} />
                                </FormItem>

                            </Col>
                            <Col md={12}>
                                <Table hover responsive className="table-outline mb-0 d-none d-sm-table" style={{ display: 'flex', justifyContent: 'center' }} striped>
                                    <thead className="bg-secondary text-black">
                                        <tr>

                                            <th className="text-center">Code</th>
                                            <th className="text-center">Désignation</th>
                                            <th className="text-center">Qté Sortir</th>
                                            {/* <th className="text-center">Qté Potentiel Stock</th>
                                            <th className="text-center">Qté Potentiel Restant</th>
                                            <th className="text-center">Retirer</th>
                    */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listeArticlesSelected && listeArticlesSelected.length ?
                                                listeArticlesSelected.map((article, k) => {
                                                    //const puRevient = parseFloat(cleRepartition + article.puEntree)
                                                    return (
                                                        <tr key={k}>

                                                            <td>{article.codProd}</td>
                                                            <td className="text-center">{article.designation}</td>
                                                            <td className="text-center">{article.qteSortie}</td>
                                                            {/* <td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qteStockEnCoursValidation) + parseFloat(article.qtEntree)}</td>

                                                            <td className="text-center">{parseFloat(article.qteStock) - parseFloat(article.qteStockEnCoursValidation)}</td>

                                                            <Button disabled className="text-center" type="danger" onClick={() => this.handleDeleteArticlesSelected(article)}>
                                                                <i className="icon icon-close-circle" />
                                                    </Button>*/}

                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </Form>
                </Modal>

                <Modal
                    width={800}
                    visible={isAddAgenceModalVisible}
                    title="Ajouter une Agence"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelAddAgence}
                    footer={[
                        <Button type="primary" loading={loading} onClick={this.handleAddAgence}>
                            Enrégistrer
                        </Button>,
                        <Button type="danger" onClick={this.handleCancelAddAgence}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>

                            <Col md={4}>
                                <FormGroup>
                                    <Label for="codificationAgence">Codification</Label>
                                    <Input type="text" name="codificationAgence" value={codificationAgence} id="codificationAgence" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="nomAgence">Nom Agence</Label>
                                    <Input type="text" name="nomAgence" value={nomAgence} onChange={this.handleChange} id="nomAgence" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>



                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="adrAgence">Adresse</Label>
                                    <Input type="text" name="adrAgence" id="adrAgence" value={adrAgence} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="telAgence">Téléphone</Label>
                                    <Input type="number" name="telAgence" value={telAgence} id="telAgence" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>

                            <Col md={6}>
                                <FormGroup >
                                    <Label for="nomGerant">Nom Gérant</Label>
                                    <Input type="text" name="nomGerant" value={nomGerant} id="nomGerant" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="telGerant">Téléphone Gérant</Label>
                                    <Input type="number" name="telGerant" value={telGerant} id="telGerant" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Label for="observation">Observation</Label>
                            <Input type="text" name="observation" value={observation} id="observation" onChange={this.handleChange} placeholder="" />
                        </FormGroup>


                    </Form>
                </Modal>



                <Modal
                    width={500}
                    visible={isDetailsArticleVisible}
                    title={titleDetailsArticle}
                    //onOk={this.handleAddOpen}
                    onCancel={this.closeDetailArticleModal}
                    footer={[
                        <Button type="primary" loading={loading} onClick={this.handleArticleSelected}>
                            Ajouter
                        </Button>,
                        <Button type="danger" onClick={this.closeDetailArticleModal}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="qtEntree">Qté à Sortir</Label>
                                    <Input type="number" name="qtEntree" id="qtEntree" value={qtEntree} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="qteStock">Stok Potentiel Restant</Label>
                                    <Input disabled type="number" name="qteStock" value={parseFloat(qteStock) - parseFloat(qteStockEnCoursValidation)} onChange={this.handleChange} id="qteStock" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                <Modal
                    width={500}
                    visible={isDetailsArticleVisibleUpdate}
                    title={titleDetailsArticle}
                    //onOk={this.handleAddOpen}
                    onCancel={this.closeDetailArticleModalUpdate}
                    footer={[
                        <Button type="primary" loading={loading} onClick={this.handleArticleSelectedUpdate}>
                            Ajouter
                        </Button>,
                        <Button type="danger" onClick={this.closeDetailArticleModalUpdate}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="qtEntree">Qté à Sortir</Label>
                                    <Input type="number" name="qtEntree" id="qtEntree" value={qtEntree} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="qteStock">Stok Potentiel Restant</Label>
                                    <Input disabled type="number" name="qteStock" value={parseFloat(qteStock) - parseFloat(qteStockEnCoursValidation)} onChange={this.handleChange} id="qteStock" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                <Modal
                    width={400}
                    visible={isValidationValidation}
                    title="Validation Sortie"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCloseValidation}
                    footer={[
                        <Button type="primary" loading={loading} onDoubleClick={this.handleValiderRepartition}>
                            Valider
                        </Button>,
                        <Button type="danger" onClick={this.handleCloseValidation}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <h5>
                        Voulez vous valider cette Sortie...?
                    </h5>
                    <p style={{ color: "red" }}>Si OUI, veuillez double cliquer sur le boutton VALIDER</p>

                </Modal>

            </div>
        )
    }
}


export default Sorties;
