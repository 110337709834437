import React from "react";
import { Card, Input, Button, Modal, Select, message, Popconfirm, DatePicker, Checkbox } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, Form, FormGroup, Label, Spinner } from 'reactstrap';
import Media from 'react-media';


import { services } from '../../../services/services';

import { authenticationService } from "../../../services/authentication-service";
import { use_logiciel } from '../../../env'
//import { last } from "lodash";
import { numberWithCommas } from '../../../helpers/index';
import { Link } from "react-router-dom";



const currentUser = authenticationService.currentUserValue;

const moment = require('moment')
//const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;

//Récuppérer le premier et le dernier jour du mois 
const dateTime = new Date(), y = dateTime.getFullYear(), m = dateTime.getMonth();
const firstDay = new Date(y, m, 1);
const lastDay = new Date(y, m + 1, 0);

class Journee extends React.Component {
    state = {
        isModalVisible: false,
        isAddModalVisible: false,
        isUpdateModalVisible: false,

        loading: false,
        journees: [],

        agences: [],

        qui: '',
        soldeJour: '',
        dateJour: '',
        codAgence: '',
        situationCaisse: '',
        agence: '',

        codAgenceChange: '',

        startDate: firstDay,
        endDate: lastDay,

        waitingEndMsg: '',
        isLoading: true,
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }



    componentDidMount() {
        const startDate = moment(new Date(firstDay), 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(new Date(lastDay), 'DD/MM/YYYY').format('YYYY-MM-DD')

        services.getJournee('', startDate, endDate)
            .then(journees => {
                console.log('journees', journees)
                this.setState({ journees })
                setTimeout(() => {

                    // console.log("real journees", factures)
                    if (!journees || !Array.isArray(journees)
                        || !journees.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucune Journée actuellement !" })
                    }
                }, 500)
            })
            .catch(() => {
                message.error('Une erreur est survenue lors du chargement des données, Veuillez réessayer plus tard', 10)
                this.setState({ isLoading: false, waitingEndMsg: "Une erreur est survenue lors du chargement des données, Veuillez réessayer plus tard!" })

            });
        services.getAllAgences()
            .then(agences => {
                //  //console.log('agences', agences)
                this.setState({ agences })
            })

    }

    onDateChange = (date, dateString) => {
        // //console.log("DATE", date, dateString, dateString[0]);
        this.setState({ isLoading: true, factures: [] })

        const startDate = moment(dateString[0], 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(dateString[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
        //  //console.log("DATE CHANGE PERIODE", dateString[0], dateString[1], this.state.dateEnCours, this.state.codAgenceChange, startDate, endDate)

        services.getJournee(this.state.codAgenceChange, startDate, endDate)
            .then(journees => {
                //console.log('journees', journees)
                this.setState({ journees })
                setTimeout(() => {

                    // console.log("real journees", factures)
                    if (!journees || !Array.isArray(journees)
                        || !journees.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucune Journé actuellement !" })
                    }
                }, 500)
            })
            .catch(() => {
                message.error('Une erreur est survenue lors du chargement des données, Veuillez réessayer plus tard', 10)
                this.setState({ isLoading: false })

            });

        this.setState({ startDate: dateString[0], endDate: dateString[1] })

    }

    handleChangeAgence = (value) => {

        const startDate = moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        const endDate = moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        this.setState({ codAgenceChange: value })
        this.setState({ isLoading: true, waitingEndMsg: '', journees: [] })
        //  //console.log("AGENCE CHANGE", this.state.dateEnCours,value, startDate, endDate)

        services.getJournee(value, startDate, endDate)
            .then(journees => {
                console.log('journees', journees)
                this.setState({ journees })
                setTimeout(() => {

                    // console.log("real journees", factures)
                    if (!journees || !Array.isArray(journees)
                        || !journees.length > 0) {
                        this.setState({ isLoading: false, waitingEndMsg: "Vous n'avez aucune Journée actuellement pour cette Agence !" })
                    }
                }, 500)
            })
            .catch(() => {
                message.error('Une erreur est survenue lors du chargement des données, Veuillez réessayer plus tard', 10)
                this.setState({ isLoading: false })

            });
    }


    handleAddOpen = () => {
        this.setState({
            isAddModalVisible: !this.state.isAddModalVisible,
            qui: '',
            soldeJour: '',
            dateJour: '',
            codAgence: '',
            situationCaisse: '',
            agence: '',

            loading: false,
        });
    };

    handleCancelAdd = () => {
        this.setState({
            isAddModalVisible: !this.state.isAddModalVisible,
            qui: '',
            soldeJour: '',
            dateJour: '',
            codAgence: '',
            situationCaisse: '',
            agence: '',
        })
    }

    handleAddJournee = () => {
        const {

            dateJour

        } = this.state


        if (dateJour === '') {
            message.warning('Veuillez sélectionner une Date', 5)
            return;
        }

        this.setState({ loading: true })

        let req = {
            // codeDep,
            dateJour: dateJour,
        }
        services.addJournee(req)
            .then(result => {
                // console.log(result)
                this.setState({
                    qui: '',
                    soldeJour: '',
                    dateJour: '',
                    codAgence: '',
                    situationCaisse: '',
                    agence: '',
                    isAddModalVisible: !this.state.isAddModalVisible,
                    loading: false,

                })
                message.success('Journée ajouter avec succès', 10)

                const startDate = moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
                const endDate = moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')

                services.getJournee('', startDate, endDate)
                    .then(journees => {
                        this.setState({ journees, loading: false })
                    })

            })

            .catch((err) => {
                message.error(err, 10)
                this.setState({ loading: false })
            });
    }

    handleCloseJournee = () => {
        const {

            dateJour,
            codAgence,
            situationCaisse,
            soldeJour,

        } = this.state

        if (dateJour === '') {
            message.warning('Veuillez préciser la Date du jour', 5)
            return;
        }

        if (soldeJour > 0 && situationCaisse === 0) {
            message.warning('Le montant de la situation de la Caisse ne peut etre égal á 0', 10)
            return;
        }

        if (situationCaisse === '' || situationCaisse === null || situationCaisse === undefined) {
            message.warning('Veuillez préciser le montant de la situation de la caisse', 5)
            return;
        }

        this.setState({ loading: true })


        let req = {
            dateJour,
            codAgence,
            soldeJour,
            situationCaisse,
        }
        services.cloturerJournee(req)
            .then(result => {
                // console.log(result)
                this.setState({
                    dateJour: '',
                    codAgence: '',
                    soldeJour: '',
                    situationCaisse: '',

                    agence: '',
                    isUpdateModalVisible: !this.state.isUpdateModalVisible,
                    loading: false,

                })
                message.success('Journée  clôturers avec succès', 10)

                const startDate = moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
                const endDate = moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')

                services.getJournee('', startDate, endDate)
                    .then(journees => {
                        this.setState({ journees })
                    })

            })

            .catch(() => {
                message.error('Une erreur est survenue lors de la modification, Veuillez réessayer plus tard', 10)
                this.setState({ loading: false, })

            });
    }

    handleDelete = (row) => {
        this.setState({
            codAgence: row.codAgence,
            dateJour: row.dateJour,
        })

    }

    handleDeleteJournee = () => {

        const { dateJour, codAgence } = this.state

        let req = {
            dateJour,
            codAgence,
        }

        services.deleteJournee(req)
            .then(del => {
                //console.log('delete user', del)
                message.success('Journée supprimer avec succès', 10)


                const startDate = moment(this.state.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
                const endDate = moment(this.state.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')

                services.getJournee('', startDate, endDate)
                    .then(journees => {
                        this.setState({ journees })
                    })
            })
            .catch((err) => {
                //console.log(err)
                message.error(err, 10)
            });

    }

    handleClotureOpen = (row) => {
        this.setState({
            isUpdateModalVisible: !this.state.isUpdateModalVisible,
            //idArticle: row.id,

            qui: row.qui,
            soldeJour: row.soldeJour,
            codAgence: row.codAgence,
            dateJour: row.dateJour,
            situationCaisse: row.situationCaisse,
            agence: row.agences && row.agences.nomAgence,

        })

    }
    handleCancelUpdate = () => {
        this.setState({
            isUpdateModalVisible: !this.state.isUpdateModalVisible,

            qui: '',
            soldeJour: '',
            dateJour: '',
            codAgence: '',
            situationCaisse: '',
            agence: '',

        })
    }


    handleView = (row) => {
        // console.log('ROW', row)
        this.setState({
            isModalVisible: !this.state.isModalVisible,
            qui: row.qui,
            soldeJour: row.soldeJour,
            dateJour: row.dateJour,
            codAgence: row.codAgence,
            situationCaisse: row.situationCaisse,
            agence: row.agences && row.agences.nomAgence,

        });
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            qui: '',
            soldeJour: '',
            dateJour: '',
            codAgence: '',
            situationCaisse: '',
            agence: '',
        });
    }


    handleBlur = () => {
        //  console.log('blur');
    }

    handleFocus = () => {
        // console.log('focus');
    }




    render() {
        const {
            isModalVisible,
            loading,


            dateJour,
            soldeJour,
            //codeNatDep,
            //codeDep,
            //montantDep,
            situationCaisse,

            qui,
            //user,
            agence,
            //dateDep,
            //libelleNatDep,



            journees,

            codAgenceChange,
            agences,
            endDate,
            startDate,

            isAddModalVisible,
            isUpdateModalVisible,

            isLoading,
            waitingEndMsg,

        } = this.state

        //const InputGroup = Input.Group;
        const Option = Select.Option;

        const { SearchBar } = Search;

        const columnsJournees = [
            /*{
            dataField: 'id',
            text: ' ID'
          },*/
            {
                dataField: 'dateJour',
                text: ' Date Journée',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {moment(new Date(row.dateJour), 'YYYY-MM-DD').format('DD/MM/YYYY')}
                        </div>
                    );
                },
            },

            {
                dataField: 'soldeJour',
                text: 'Solde Jour ',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {numberWithCommas(parseFloat(row.soldeJour) || 0)}
                        </div>
                    );
                },
            },

            {
                dataField: 'situationCaisse',
                text: 'Solde Saisi ',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {numberWithCommas(parseFloat(row.situationCaisse) || 0)}
                        </div>
                    );
                },
            },
            {
                dataField: 'sens',
                text: 'Différrence',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {numberWithCommas(parseFloat(row.soldeJour) - parseFloat(row.situationCaisse) || 0)}
                        </div>
                    );
                },
            },
            {
                dataField: 'valider',
                text: 'Clôturer',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {row && row.valider ? <Checkbox checked={true}  ></Checkbox> : <Checkbox checked={false}  ></Checkbox>}
                        </div>
                    );
                },
            },
            {
                dataField: 'agences',
                text: 'Agence',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {row && row.agences && row.agences.nomAgence}
                        </div>
                    );
                },
            },


            // (currentUser.agence.enCours===true && currentUser.slug==="ADMIN_ROLE"  ) &&
            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                hidden: ((currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "AGENT_ROLE") || (currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "ADMIN_ROLE")) ? true : false,
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            <Button
                                title="Details Journée"
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="small"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleView(row)}
                            >
                                <i className="icon icon-custom-view" />
                            </Button>

                            {row && row.valider ?
                                ''
                                :
                                <Button
                                    title="Cloturer Journée"

                                    hidden={use_logiciel === 'NO' ? false : true}
                                    //type="primary"
                                    className="gx-btn-cyan"
                                    //fullWidth
                                    variant="contained"
                                    color="danger"
                                    size="small"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleClotureOpen(row)}
                                >
                                    <i className="icon icon-check-square-o" />
                                </Button>
                            }
                            {row && row.valider ?
                                ''
                                :
                                <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cette Journée?" onConfirm={this.handleDeleteJournee} onCancel={this.cancelDelete} okText="Oui"
                                    cancelText="Non">
                                    <Button
                                        title="Suppression Journée"

                                        hidden={use_logiciel === 'NO' ? false : true}
                                        type="danger"
                                        //fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        //className="btn btn-success btn-sm"
                                        onClick={() => this.handleDelete(row)}
                                    >
                                        <i className="icon icon-alert-new" />
                                    </Button>

                                </Popconfirm>
                            }

                            <Link to={`${process.env.PUBLIC_URL}/gestion/caisse/operations?${row.dateJour}`}>
                                <Button
                                    size="small"
                                    className="gx-btn-secondary "
                                    title="Operations Journée"

                                >
                                    <i title={"Modifier Commande"} className="icon icon-crm" />

                                </Button>
                            </Link>

                        </div>
                    );
                },


            }

        ];


        const columnsJourneesPhone = [

            {
                dataField: 'dateJour',
                text: ' Date Journée',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {moment(new Date(row.dateJour), 'YYYY-MM-DD').format('DD/MM/YYYY')}
                        </div>
                    );
                },
            },

            {
                dataField: 'soldeJour',
                text: 'Solde Jour ',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            {numberWithCommas(parseFloat(row.soldeJour) || 0)}
                        </div>
                    );
                },
            },

            // (currentUser.agence.enCours===true && currentUser.slug==="ADMIN_ROLE"  ) &&
            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                hidden: ((currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "AGENT_ROLE") || (currentUser && currentUser.agence && currentUser.agence.enCours === false && currentUser.slug === "ADMIN_ROLE")) ? true : false,
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            <Button
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="small"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleView(row)}
                            >
                                <i className="icon icon-custom-view" />
                            </Button>

                            {row && row.valider ?
                                ''
                                :
                                <Button
                                    hidden={use_logiciel === 'NO' ? false : true}
                                    type="primary"
                                    //fullWidth
                                    variant="contained"
                                    color="danger"
                                    size="small"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleClotureOpen(row)}
                                >
                                    <i className="icon icon-check-square-o" />
                                </Button>
                            }
                            {row && row.valider ?
                                ''
                                :
                                <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer cette Journée?" onConfirm={this.handleDeleteJournee} onCancel={this.cancelDelete} okText="Oui"
                                    cancelText="Non">
                                    <Button
                                        hidden={use_logiciel === 'NO' ? false : true}
                                        type="danger"
                                        //fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        //className="btn btn-success btn-sm"
                                        onClick={() => this.handleDelete(row)}
                                    >
                                        <i className="icon icon-alert-new" />
                                    </Button>

                                </Popconfirm>
                            }

                        </div>
                    );
                },


            }

        ];

        const defaultSorted = [{
            dataField: 'dateJour',
            order: 'desc'
        }];



        return (
            <div>
                <Card title="Liste des Journées">

                    <Media query="(min-width: 600px)" render={() =>
                    (
                        <ToolkitProvider
                            keyField="id"
                            data={journees}
                            columns={columnsJournees}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={3} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 350, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>

                                            <Col md={9} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                <FormGroup className="mr-2">
                                                    <RangePicker className="gx-w-100"
                                                        defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                        format={dateFormat}
                                                        onChange={this.onDateChange}
                                                        ranges={{
                                                            "Aujourd'hui": [moment(), moment()],
                                                            'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                            'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                            'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                        }}
                                                    />
                                                    <h6 className="mr-3" style={{ color: "black", }}><span>Filtrer par Période</span></h6>
                                                </FormGroup>


                                                {(currentUser && currentUser.agence && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") &&
                                                    <FormGroup>
                                                        <Select

                                                            value={codAgenceChange}
                                                            showSearch
                                                            //style={{width: 200}}
                                                            style={{ width: '120%' }}
                                                            placeholder="Filtrer par Agence"
                                                            optionFilterProp="children"
                                                            onChange={this.handleChangeAgence}
                                                            onFocus={this.handleFocus}
                                                            onBlur={this.handleBlur}
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option value=''>TOUTES</Option>
                                                            {
                                                                agences && agences.length ?
                                                                    agences.map((agence, k) => {
                                                                        return <Option key={k} value={agence.codAgence}>{agence.nomAgence}</Option>
                                                                    }) : null
                                                            }

                                                        </Select>
                                                        <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                                    </FormGroup>}

                                                {use_logiciel === 'NO' &&
                                                    <button style={{ marginLeft: 35 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAddOpen}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                }
                                            </Col>
                                        </Row>


                                        <BootstrapTable
                                            {...props.baseProps}
                                            //bootstrap4
                                            wrapperClasses="table-responsive"
                                            //selectRow={ selectRow }
                                            //defaultSorted={defaultSorted}
                                            pagination={paginationFactory()}
                                            defaultSorted={defaultSorted}
                                            striped
                                            hover
                                            condensed
                                            headerWrapperClasses="foo"
                                            noDataIndication="Vous n'avez aucun enrégistrement pour cette période, veuillez cliquer sur Nouveau pour en ajouter"
                                        //filter={filterFactory()}
                                        />

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />


                    <Media query="(max-width: 599px)" render={() =>
                    (

                        <ToolkitProvider
                            keyField="id"
                            data={journees}
                            columns={columnsJourneesPhone}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                <i className="fa fa-search" style={{ margin: 10 }}></i>

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 550, width: '125%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>
                                            <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                <FormGroup className="mr-2">
                                                    <RangePicker className="gx-w-100"
                                                        defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                                        format={dateFormat}
                                                        onChange={this.onDateChange}
                                                        ranges={{
                                                            "Aujourd'hui": [moment(), moment()],
                                                            'Cette Semaine': [moment().startOf('week'), moment().endOf('week')],
                                                            'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
                                                            'Cette Année': [moment().startOf('year'), moment().endOf('year')]

                                                        }}
                                                    />
                                                    <h6 className="mr-3" style={{ color: "black", }}><span>Filtrer par Période</span></h6>
                                                </FormGroup>
                                            </Col>

                                            <Col md={6}>
                                                {(currentUser && currentUser.agence && currentUser.agence.enCours === true && currentUser.slug === "ADMIN_ROLE") &&
                                                    <FormGroup>
                                                        <Select

                                                            value={codAgenceChange}
                                                            showSearch
                                                            //style={{width: 200}}
                                                            style={{ width: '100%' }}
                                                            placeholder="Filtrer par Agence"
                                                            optionFilterProp="children"
                                                            onChange={this.handleChangeAgence}
                                                            onFocus={this.handleFocus}
                                                            onBlur={this.handleBlur}
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option value=''>TOUTES</Option>
                                                            {
                                                                agences && agences.length ?
                                                                    agences.map((agence, k) => {
                                                                        return <Option key={k} value={agence.codAgence}>{agence.nomAgence}</Option>
                                                                    }) : null
                                                            }

                                                        </Select>
                                                        <h6 className="mr-3" style={{ color: "black" }}><span>Filtrer par Agence</span></h6>
                                                    </FormGroup>}
                                            </Col>
                                            <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                {use_logiciel === 'NO' &&
                                                    <button style={{ marginLeft: 35 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAddOpen}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                }
                                            </Col>

                                        </Row>

                                        {
                                            !isLoading && waitingEndMsg && waitingEndMsg !== '' && <div><h5 className="card-title text-danger">{waitingEndMsg}</h5></div>
                                        }
                                        {
                                            journees &&
                                                Array.isArray(journees) &&
                                                journees.length > 0 ?
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    //bootstrap4
                                                    wrapperClasses="table-responsive"
                                                    //selectRow={ selectRow }
                                                    //defaultSorted={defaultSorted}
                                                    pagination={paginationFactory()}
                                                    defaultSorted={defaultSorted}
                                                    striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                                                //filter={filterFactory()}
                                                />
                                                :
                                                isLoading ?
                                                    <div style={{ justifyContent: 'center' }}>

                                                        <Row style={{ justifyContent: 'center' }}>
                                                            <Col style={{ justifyContent: 'center' }}>
                                                                <Spinner type="grow" color="primary" />
                                                                <Spinner type="grow" color="secondary" />
                                                                <Spinner type="grow" color="success" />
                                                                <Spinner type="grow" color="danger" />
                                                                <Spinner type="grow" color="warning" />
                                                                <Spinner type="grow" color="info" />
                                                                <Spinner type="grow" color="light" />
                                                                <Spinner type="grow" color="dark" />
                                                            </Col>
                                                        </Row> <br />
                                                    </div>
                                                    : null
                                        }

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />
                </Card>


                <Modal
                    width={600}
                    visible={isAddModalVisible}
                    title="Ajouter une Journée"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelAdd}
                    footer={[
                        <Button type="primary" loading={loading} onClick={this.handleAddJournee}>
                            Créer
                        </Button>,
                        <Button type="danger" onClick={this.handleCancelAdd}>
                            Annuler
                        </Button>

                    ]}
                >
                    <Form>
                        <Row form>


                            <Col md={12}>
                                <FormGroup>
                                    <Label for="dateJour">Date de la Journée</Label>
                                    <Input type="date" name="dateJour" value={dateJour} onChange={this.handleChange} id="dateJour" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>




                    </Form>
                </Modal>

                <Modal
                    width={800}
                    visible={isUpdateModalVisible}
                    title="Clôturer la Journée"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelUpdate}
                    footer={[
                        <Button type="primary" loading={loading} onClick={this.handleCloseJournee}>
                            Clôturer
                        </Button>,
                        <Button type="danger" onClick={this.handleCancelUpdate}>
                            Annuler
                        </Button>,
                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="dateJour">Date de la Journée</Label>
                                    <Input disabled type="date" name="dateJour" value={dateJour} onChange={this.handleChange} id="dateJour" placeholder="" />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="agence">Agence</Label>
                                    <Input disabled type="text" name="agence" value={agence} onChange={this.handleChange} id="agence" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="montantDep">Solde Jour</Label>
                                    <Input disabled type="text" name="montantDep" value={soldeJour} id="montantDep" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="situationCaisse">Situation Caisse</Label>
                                    <Input type="text" name="situationCaisse" value={situationCaisse} onChange={this.handleChange} id="situationCaisse" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                <Modal
                    width={800}
                    visible={isModalVisible}
                    title="Détails Journée"
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button type="danger" onClick={this.handleCancel}>
                            Retour
                        </Button>,
                        /*<Button  type="primary" loading={loading} onClick={this.handleCancel}>
                          Envoyer
                        </Button>,
                       */
                    ]}
                >
                    <Form>
                        <Row form>


                            <Col md={12}>
                                <FormGroup>
                                    <Label for="dateJour">Date de la Journée</Label>
                                    <Input disabled type="date" name="dateJour" value={dateJour} onChange={this.handleChange} id="dateJour" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="montantDep">Solde Jour</Label>
                                    <Input disabled type="text" name="montantDep" value={numberWithCommas(soldeJour || 0)} id="montantDep" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="situationCaisse">Situation Caisse</Label>
                                    <Input disabled type="text" name="situationCaisse" value={numberWithCommas(situationCaisse || 0)} onChange={this.handleChange} id="situationCaisse" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="agence">Agence</Label>
                                    <Input disabled type="text" name="agence" value={agence} onChange={this.handleChange} id="agence" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="qui">Cloturer par</Label>
                                    <Input disabled type="text" name="qui" value={qui} id="qui" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                    </Form>
                </Modal>

            </div>
        )
    }
}


export default Journee;
