import React from "react";
import {authenticationService} from "../../services/authentication-service";

const currentUser = authenticationService.currentUserValue
const AppsNavigation = () =>
  <ul className="gx-app-nav" style={{justifyContent:'center'}}>
    {currentUser? (currentUser && (currentUser.description? currentUser.description.toUpperCase(): '')) :''}
    {/*<li><i className="icon icon-search-new"/></li>
    <li><i className="icon icon-notification"/></li>
<li><i className="icon icon-chat-new"/></li> */}
  </ul>;

export default AppsNavigation;
