import React from "react";
import {Route, Switch} from "react-router-dom";

import Recettes from "./Recette";
import Depenses from "./Depense";
import Journee from "./Journee";
import Operation from "./Operations";



 
const Caisse = ({match}) => (
  <Switch>
    <Route path={`${match.url}/recettes`}    component={Recettes}/>   
    <Route path={`${match.url}/depenses`}    component={Depenses}/>   
    <Route path={`${match.url}/journees`}    component={Journee}/>   
    <Route path={`${match.url}/operations`}    component={Operation}/>   
   
  </Switch>
);

export default Caisse;
