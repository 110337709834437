import React from "react";
import { Card, Input, Button, Modal, Select, message, Popconfirm, DatePicker, Radio } from "antd";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col, Form, FormGroup, Label, } from 'reactstrap';
import Media from 'react-media';
import moment from 'moment';
import 'moment/locale/fr-ca';
import locale from 'antd/es/date-picker/locale/fr_FR';

import { services } from '../../../services/services'
import { use_logiciel } from '../../../env'


const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

//const InputGroup = Input.Group;

/*const optionsWithDisabled = [
    { label: 'Aucune Option', value: '' },
    { label: 'Nouveau Client', value: 'NOUVEAU CLIENT' },
    { label: 'Ancien Client', value: 'ANCIEN CLIENT', },
];*/

class Clients extends React.Component {
    state = {
        isModalVisible: false,
        isAddModalVisible: false,
        isUpdateModalVisible: false,
        loading: false,
        clients: [],
        //agences: [],
        gender: 'M',
        raisonSociale: '',
        codClient: '',
        mentionClient: 'NOUVEAU CLIENT',
        typeClient: 'CLIENT ORDINAIRE',
        usuel: 'Usuel',
        dateNaissance: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
        ifu: '',
        adresse:'',
        contact: '',
        profession: '',
        email: '',
        siteWeb: '',
        contact2: '',
        numeroCarte: '',
    }


    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        services.getClients()
            .then(clients => {
               // console.log('clients', clients)
                this.setState({ clients })
            })
    }

    handleAddOpen = () => {
        this.setState({
            isAddModalVisible: !this.state.isAddModalVisible,
            codClient: '',
            raisonSociale: '',
            ifu: '',
            adresse: '',
            contact: '',
            contact2: '',
            gender: 'M',
            usuel: '1', 
            mentionClient: 'NOUVEAU CLIENT',
            dateNaissance: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
            typeClient: 'CLIENT ORDINAIRE',
            siteWeb: '',
            email: '',
            profession: '',
            loading: false

        });
    };

    handleCancelAdd = () => {
        this.setState({
            isAddModalVisible: !this.state.isAddModalVisible,
            codClient: '',
            raisonSociale: '',
            ifu: '',
            adresse: '',
            contact: '',
            contact2: '',
            gender: '',
            loading: false

        })
    }

    handleAddClient = () => {
        const {
            raisonSociale,
            ifu,
            adresse,
            contact,
            contact2,
            gender,
            usuel,
            dateNaissance,
            typeClient,
            siteWeb,
            email,
            profession,
            mentionClient,
            numeroCarte,
        } = this.state


        if (raisonSociale === '') {
            message.warning('Veuillez renseigner le champ Raison sociale', 5)
            return;
        }
        if (contact === '') {
            message.warning('Veuillez renseigner le champ Téléphone', 5)
            return;
        }

        this.setState({loading: true})
        let req = {
            raisonSociale: raisonSociale.toUpperCase(),
            ifu,
            adresse,
            contact,
            contact2,
            gender,
            usuel,
            dateNaissance,
            typeClient,
            siteWeb,
            email,
            profession,
            mentionClient,
            numeroCarte,

        }
        services.addClient(req)
            .then(result => {
               // console.log(result)
                this.setState({
                    raisonSociale: '',
                    ifu: '',
                    adresse: '',
                    contact: '',
                    contact2: '',
                    gender: 'M',
                    usuel: '',
                    mentionClient: 'NOUVEAU CLIENT',
                    dateNaissance: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    typeClient: '',
                    siteWeb: '',
                    email: '',
                    profession: '',
                    numeroCarte:'',
                    isAddModalVisible: !this.state.isAddModalVisible,
                    loading: false,

                })
                message.success('Client ajouter avec succès', 10)

                services.getClients()
                    .then(clients => {
                        this.setState({ clients })
                    })

            })

            .catch((err) => {
                this.setState({ loading: false })
                message.error(err, 10)

            });
    }

    handleUpdateClient = () => {
        const {
            codClient,
            raisonSociale,
            ifu,
            adresse,
            contact,
            contact2,
            gender,
            usuel,
            dateNaissance,
            typeClient,
            siteWeb,
            email,
            profession,
            mentionClient,
            numeroCarte,
        } = this.state


        if (raisonSociale === '') {
            message.warning('Veuillez renseigner le champ Raison sociale', 5)
            return;
        }
        if (contact === '') {
            message.warning('Veuillez renseigner le champ Téléphone', 5)
            return;
        }


        let req = {
            raisonSociale: raisonSociale.toUpperCase(),
            ifu,
            adresse,
            contact,
            contact2,
            gender,
            usuel,
            dateNaissance,
            typeClient,
            siteWeb,
            email,
            profession,
            mentionClient,
            numeroCarte,

        }
        console.log('REQ Update', req)
        services.updateClient(codClient, req)
            .then(result => {
               // console.log(result)
                this.setState({
                    codClient: '',
                    raisonSociale: '',
                    ifu: '',
                    adresse: '',
                    contact: '',
                    contact2: '',
                    gender: 'M',
                    usuel: '',
                    mentionClient: 'NOUVEAU CLIENT',
                    dateNaissance: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    typeClient: '',
                    siteWeb: '',
                    email: '',
                    profession: '',
                    numeroCarte:'',
                    isUpdateModalVisible: !this.state.isUpdateModalVisible,

                })
                message.success('Client modifier avec succès', 10)

                services.getClients()
                    .then(clients => {
                        this.setState({ clients })
                    })

            })

            .catch(() => {
                message.error('Une erreur est survenue lors de la modification, Veuillez réessayer plus tard', 10)
            });
    }

    handleDelete = (row) => {
        this.setState({
            codClient: row.codClient,
            raisonSociale: row.raisonSociale,
            adresse: row.adresse,
        })

    }

    handleDeleteClient = () => {

        const { codClient } = this.state

        services.deleteClient(codClient)
            .then(del => {
                //console.log('delete user', del)
                message.success('Client supprimer avec succès', 10)
                services.getClients()
                    .then(clients => {
                        this.setState({ clients })
                    })

            })
            .catch((err) => {
                message.error(err, 10)
            });

    }

    handleUpdateOpen = (row) => {
        this.setState({
            isUpdateModalVisible: !this.state.isUpdateModalVisible,
            codClient: row.codClient,
            raisonSociale: row.raisonSociale,
            numeroCarte: row.numeroCarte,
            ifu: row.ifu,
            adresse: row.adresse,
            contact: row.contact,
            contact2: row.contact2,
            gender: row.gender,
            usuel: row.usuel,
            dateNaissance: moment(new Date(row.dateNaissance), 'DD/MM/YYYY').format('DD/MM/YYYY') ,
            typeClient: row.typeClient,
            siteWeb: row.siteWeb,
            email: row.email,
            profession: row.profession,
            mentionClient: row.mentionClient,

        })

    }
    handleCancelUpdate = () => {
        this.setState({
            isUpdateModalVisible: !this.state.isUpdateModalVisible,
            codClient: '',
            raisonSociale: '',
            ifu: '',
            adresse: '',
            contact: '',
            contact2: '',
            gender: 'M',
            usuel: '',
            mentionClient: 'NOUVEAU CLIENT',
            dateNaissance: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
            typeClient: '',
            siteWeb: '',
            email: '',
            profession: '',
        })
    }


    handleView = (row) => {
       // console.log('row', row)
        this.setState({
            isModalVisible: !this.state.isModalVisible,
            codClient: row.codClient,
            numeroCarte: row.numeroCarte,
            raisonSociale: row.raisonSociale,
            ifu: row.ifu,
            adresse: row.adresse,
            contact: row.contact,
            contact2: row.contact2,
            gender: row.gender,
            usuel: row.usuel,
            dateNaissance:  moment(new Date(row.dateNaissance), 'DD/MM/YYYY').format('DD/MM/YYYY'),
            typeClient: row.typeClient,
            siteWeb: row.siteWeb,
            email: row.email,
            profession: row.profession,
            mentionClient: row.mentionClient,

        });
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            codClient: '',
            raisonSociale: '',
            ifu: '',
            adresse: '',
            contact: '',
            contact2: '',
            gender: 'M',
            usuel: '',
            mentionClient: 'NOUVEAU CLIENT',
            dateNaissance: moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY'),
            typeClient: '',
            siteWeb: '',
            email: '',
            profession: '',
        });
    }

    handleChangeGender = (value) => {
        this.setState({ gender: value })
    }


    onChangeMention = e => {
        //console.log('radio4 checked', e.target.value);
        this.setState({
            mentionClient: e.target.value,
        });
    };

    handleChangeUsuel = (value) => {
        this.setState({ usuel: value })
    }

    handleChangeDate = (date, dateString) => {
       // console.log(dateString, moment(dateString).format('DD/MM/YYYY'), moment(date, dateFormatList[0]));
        this.setState({ dateNaissance: dateString })
    }

    handleChangeTypeClient = (value) => {
        this.setState({ typeClient: value })
    }

    render() {
        const {
            isModalVisible,
            loading,
            //agences,

            //codClient,
            //numeroCarte,
            raisonSociale,
            ifu,
            adresse,
            contact,
            contact2,
            gender,
            //usuel,
            dateNaissance,
            typeClient,
            siteWeb,
            email,
            profession,
            mentionClient,

            clients,


            //codificationAgence,
            //nomAgence,
            //adrAgence,
            //telAgence,
           // nomGerant,
           // telGerant,
           // observation,


            isAddModalVisible,
            isUpdateModalVisible,
            //IsModalAddAgence
        } = this.state


        const Option = Select.Option;

        const { SearchBar } = Search;

        const columnsClients = [{
            dataField: 'codClient',
            text: ' ID'
        },
        {
            dataField: 'raisonSociale',
            text: 'Raison sociale'
        },
        {
            dataField: 'ifu',
            text: 'IFU',
        },
        {
            dataField: 'contact',
            text: 'Téléphone',
        },
        {
            dataField: 'adresse',
            text: 'Adresse'
        },

        {
            dataField: '',
            text: 'Action',
            headerAlign: 'center',
            sort: true,
            headerAttrs: { width: 50 },
            align: 'center',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    < div
                        style={{ textAlign: "center" }} className="d-flex">
                        <Button
                            type="primary"
                            //fullWidth
                            variant="contained"
                            color="primary"
                            size="small"
                            //className="btn btn-success btn-sm"
                            onClick={() => this.handleView(row)}
                        >
                            <i className="icon icon-custom-view" />
                        </Button>
                        <Button
                        hidden= { use_logiciel === 'NO' ? false : true}
                            type="primary"
                            //fullWidth
                            variant="contained"
                            color="danger"
                            size="small"
                            //className="btn btn-success btn-sm"
                            onClick={() => this.handleUpdateOpen(row)}
                        >
                            <i className="icon icon-edit" />
                        </Button>
                        <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer ce Client?" onConfirm={this.handleDeleteClient} onCancel={this.cancelDelete} okText="Oui"
                            cancelText="Non">
                            <Button
                            hidden= { use_logiciel === 'NO' ? false : true}
                                type="danger"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="small"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleDelete(row)}
                            >
                                <i className="icon icon-alert-new" />
                            </Button>

                        </Popconfirm>

                    </div>
                );
            },

        }

        ];


        const columnsClientsTelephone = [

            {
                dataField: 'codClient',
                text: ' ID'
            },
            {
                dataField: 'raisonSociale',
                text: 'Raison sociale'
            },
            {
                dataField: 'ifu',
                text: 'IFU',
            },


            {
                dataField: '',
                text: 'Action',
                headerAlign: 'center',
                sort: true,
                headerAttrs: { width: 50 },
                align: 'center',
                formatter: (cell, row, rowIndex, formatExtraData) => {
                    return (
                        < div
                            style={{ textAlign: "center" }} className="d-flex">
                            <Button
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="primary"
                                size="small"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleView(row)}
                            >
                                <i className="icon icon-custom-view" />
                            </Button>
                            <Button
                            hidden= { use_logiciel === 'NO' ? false : true}
                                type="primary"
                                //fullWidth
                                variant="contained"
                                color="danger"
                                size="small"
                                //className="btn btn-success btn-sm"
                                onClick={() => this.handleUpdateOpen(row)}
                            >
                                <i className="icon icon-edit" />
                            </Button>
                            <Popconfirm placement="left" title="Êtes vous sûr de vouloir supprimer ce Fournisseur?" onConfirm={this.handleDeleteClient} onCancel={this.cancelDelete} okText="Oui"
                                cancelText="Non">
                                <Button
                                hidden= { use_logiciel === 'NO' ? false : true}
                                    type="danger"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    //className="btn btn-success btn-sm"
                                    onClick={() => this.handleDelete(row)}
                                >
                                    <i className="icon icon-alert-new" />
                                </Button>

                            </Popconfirm>

                        </div>
                    );
                },

            }

        ];

        const defaultSorted = [{
            dataField: 'raisonSociale',
            order: 'asc'
        }];



        return (
            <div>
                <Card title="Liste des Clients">

                    <Media query="(min-width: 600px)" render={() =>
                    (
                        <ToolkitProvider
                            keyField="id"
                            data={clients}
                            columns={columnsClients}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                {/*<i className="fa fa-search" style={{ margin: 10 }}></i> */}

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 750, width: '180%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>
                                            {//currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                                                <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                    <button style={{ marginLeft: 3 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAddOpen}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                </Col>

                                            }
                                        </Row>


                                        <BootstrapTable
                                            {...props.baseProps}
                                            //bootstrap4
                                            wrapperClasses="table-responsive"
                                            //selectRow={ selectRow }
                                            //defaultSorted={defaultSorted}
                                            pagination={paginationFactory()}
                                            defaultSorted={defaultSorted}
                                            striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                                        //filter={filterFactory()}
                                        />

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />


                    <Media query="(max-width: 599px)" render={() =>
                    (

                        <ToolkitProvider
                            keyField="id"
                            data={clients}
                            columns={columnsClientsTelephone}
                            //cellEdit={cellEditFactory({ mode: 'dbclick' })}
                            search
                            //insertRow={true}
                            exportCSV

                        >
                            {
                                props => (
                                    <div>
                                        <Row >
                                            <Col md={6} style={{ display: 'flex', alignItems: 'baseline' }}>
                                                <i className="fa fa-search" style={{ margin: 10 }}></i>

                                                <SearchBar {...props.searchProps}
                                                    //delay={ 1000 }
                                                    style={{ maxWidth: 450, width: '125%', margin: 'auto', display: 'flex', borderRadius: 50 }}
                                                    placeholder="Rechercher"

                                                />
                                            </Col>
                                            { use_logiciel === 'NO' && //currentUser && currentUser.slug === 'COMMERCIAL_ROLE' &&
                                                <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', }}>

                                                    <button style={{ marginLeft: 3 }}
                                                        className="btn bg-primary text-light rounded"
                                                        onClick={this.handleAddOpen}>
                                                        NOUVEAU <i className="icon icon-add" />
                                                    </button>
                                                </Col>
                                            }

                                        </Row>

                                        <BootstrapTable
                                            {...props.baseProps}
                                            ///bootstrap4
                                            wrapperClasses="table-responsive"
                                            //selectRow={ selectRow }
                                            //defaultSorted={defaultSorted}
                                            pagination={paginationFactory()}
                                            defaultSorted={defaultSorted}
                                            striped
                                                    hover
                                                    condensed
                                                    headerWrapperClasses="foo"
                                                    noDataIndication="Vous n'avez aucun enrégistrement, veuillez cliquer sur Nouveau pour en ajouter"
                                        //filter={filterFactory()}
                                        />

                                    </div>

                                )
                            }
                        </ToolkitProvider>
                    )}
                    />
                </Card>


                <Modal
                    width={800}
                    visible={isAddModalVisible}
                    title="Ajouter un Client"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelAdd}
                    footer={[
                        <Button type="primary" loading={loading} onClick={this.handleAddClient}>
                            Envoyer
                        </Button>,
                        <Button  type="danger" onClick={this.handleCancelAdd}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="raisonSociale">Raison Sociale</Label>
                                    <Input type="text" name="raisonSociale" id="raisonSociale" value={raisonSociale} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="ifu">IFU</Label>
                                    <Input maxLength={13} type="number" name="ifu" value={ifu} id="ifu" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        {/*<Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="numeroCarte">Numéro Carte</Label>
                                    <Input type="number" name="numeroCarte" id="numeroCarte" value={numeroCarte} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                </Row>*/}
                        <Row>
                            <Col md={6}>
                                <FormGroup >
                                    <Label for="gender">Sexe</Label>
                                    <Select className="gx-mr-6 gx-mb-6" value={gender} onChange={this.handleChangeGender}>
                                        <Option value="M">Masculin</Option>
                                        <Option value="F">Feminin</Option>
                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <Label for="dateNaissance">Date de Naissance</Label>
                                <FormGroup>

                                    <DatePicker style={{ width: '100%' }} name="dateNaissance" id="dateNaissance" value={moment(dateNaissance, dateFormatList[0])} locale={locale} onChange={this.handleChangeDate} format={dateFormatList} />
                                   </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="adresse">Adresse</Label>
                                    <Input type="text" name="adresse" value={adresse} onChange={this.handleChange} id="adresse" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="profession">Profession</Label>
                                    <Input type="text" name="profession" id="profession" value={profession} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>



                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="contact">Téléphone</Label>
                                    <Input type="text" name="contact" id="contact" value={contact} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="contact2">Téléphone 2</Label>
                                    <Input type="text" name="contact2" value={contact2} id="contact2" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>


                        <Row form>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input type="text" name="email" value={email} id="email" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="siteWeb">Site web</Label>
                                    <Input type="text" name="siteWeb" id="siteWeb" value={siteWeb} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="typeClient">Type de Client</Label>
                                    <Select
                                        value={typeClient}
                                        className="gx-mr-6 gx-mb-6"
                                        placeholder="Selectionner un Type"
                                        onChange={this.handleChangeTypeClient}
                                    >
                                        <Option value="CLIENT DETAILLANT">CLIENT DETAILLANT</Option>
                                        <Option value="CLIENT EN GROS">CLIENT EN GROS</Option>
                                        <Option value="CLIENT ORDINAIRE">CLIENT ORDINAIRE</Option>

                                    </Select>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <Label for="mentionClient">Mention Client</Label>
                                <FormGroup>

                                    <Radio.Group
                                    // options={optionsWithDisabled}
                                    // onChange={this.onChangeMention}
                                    //value={mentionClient}
                                    //optionType="button"
                                    //buttonStyle="solid"
                                    />
                                    <Radio.Group onChange={this.onChangeMention} value={mentionClient} defaultValue={mentionClient} buttonStyle="solid">
                                        <Radio.Button value="">Aucune option</Radio.Button>
                                        <Radio.Button value="NOUVEAU CLIENT">Nouveau Client</Radio.Button>
                                        <Radio.Button value="ANCIEN CLIENT">Ancien Client</Radio.Button>
                                    </Radio.Group>
                                </FormGroup>
                            </Col>
                            {/*<Col md={6}>
                                <FormGroup>
                                    <Label for="usuel">Usuel</Label>
                                    <Select

                                        value={usuel}
                                        className="gx-mr-6 gx-mb-6"
                                        placeholder="Selectionner une valeur"

                                        onChange={this.handleChangeUsuel}
                                    >
                                        <Option value="1">Usuel</Option>
                                        <Option value="0">Non Usuel</Option>
                                    </Select>
                                </FormGroup>
                         </Col> */}
                        </Row>
                        

                        {/*<Row form>

              <Col md={12}>
                <FormGroup>
                  <Label for="codClient">Agences</Label>
                  <InputGroup compact>

                    <Select

                      value={codClient}
                      showSearch
                      //style={{width: 200}}
                      style={{ width: '85%' }}
                      placeholder="Selectionner Agence"
                      optionFilterProp="children"
                      onChange={this.handleChangeAgence}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {
                        agences && agences.length ?
                          agences.map((agence, k) => {
                            return <Option value={agence.codClient}>{agence.nomAgence}</Option>
                          }) : null
                      }

                    </Select>
                    <Button onClick={this.OpenModalAddAgence} style={{ width: '15%' }} type="primary" ><i className="icon icon-add" /> AJOUTER</Button>
                  </InputGroup>
                </FormGroup>

              </Col>
                    </Row>*/}

                    </Form>
                </Modal>

                <Modal
                    width={800}
                    visible={isUpdateModalVisible}
                    title="Modifier Client"
                    //onOk={this.handleAddOpen}
                    onCancel={this.handleCancelUpdate}
                    footer={[
                        <Button type="primary" loading={loading} onClick={this.handleUpdateClient}>
                            Modifier
                        </Button>,
                        <Button  type="danger" onClick={this.handleCancelUpdate}>
                            Annuler
                        </Button>,

                    ]}
                >
                    <Form>


                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="raisonSociale">Raison Sociale</Label>
                                    <Input type="text" name="raisonSociale" id="raisonSociale" value={raisonSociale} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="ifu">IFU</Label>
                                    <Input type="number" name="ifu" value={ifu} id="ifu" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        {/*<Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="numeroCarte">Numéro Carte</Label>
                                    <Input type="number" name="numeroCarte" id="numeroCarte" value={numeroCarte} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                </Row>*/}
                        <Row>
                            <Col md={6}>
                                <FormGroup >
                                    <Label for="gender">Sexe</Label>
                                    <Select className="gx-mr-6 gx-mb-6" value={gender} onChange={this.handleChangeGender}>
                                        <Option value="M">Masculin</Option>
                                        <Option value="F">Feminin</Option>
                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <Label for="dateNaissance">Date de Naissance</Label>
                                <FormGroup>

                                    <DatePicker style={{ width: '100%' }} name="dateNaissance" id="dateNaissance" value={moment(dateNaissance, dateFormatList[0])} locale={locale} onChange={this.handleChangeDate} format={dateFormatList} />
                                    
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="adresse">Adresse</Label>
                                    <Input type="text" name="adresse" value={adresse} onChange={this.handleChange} id="adresse" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="profession">Profession</Label>
                                    <Input type="text" name="profession" id="profession" value={profession} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>



                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="contact">Téléphone</Label>
                                    <Input type="text" name="contact" id="contact" value={contact} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="contact2">Téléphone 2</Label>
                                    <Input type="text" name="contact2" value={contact2} id="contact2" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>


                        <Row form>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input type="text" name="email" value={email} id="email" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="siteWeb">Site web</Label>
                                    <Input type="text" name="siteWeb" id="siteWeb" value={siteWeb} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="typeClient">Type de Client</Label>
                                    <Select
                                        value={typeClient}
                                        className="gx-mr-6 gx-mb-6"
                                        placeholder="Selectionner un Type"
                                        onChange={this.handleChangeTypeClient}
                                    >
                                        <Option value="CLIENT DETAILLANT">CLIENT DETAILLANT</Option>
                                        <Option value="CLIENT EN GROS">CLIENT EN GROS</Option>
                                        <Option value="CLIENT ORDINAIRE">CLIENT ORDINAIRE</Option>

                                    </Select>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <Label for="mentionClient">Mention Client</Label>
                                <FormGroup>

                                    <Radio.Group
                                    // options={optionsWithDisabled}
                                    // onChange={this.onChangeMention}
                                    //value={mentionClient}
                                    //optionType="button"
                                    //buttonStyle="solid"
                                    />
                                    <Radio.Group onChange={this.onChangeMention} value={mentionClient} defaultValue={mentionClient} buttonStyle="solid">
                                        <Radio.Button value="">Aucune option</Radio.Button>
                                        <Radio.Button value="NOUVEAU CLIENT">Nouveau Client</Radio.Button>
                                        <Radio.Button value="ANCIEN CLIENT">Ancien Client</Radio.Button>
                                    </Radio.Group>
                                </FormGroup>
                            </Col>
                            {/*<Col md={6}>
                                <FormGroup>
                                    <Label for="usuel">Usuel</Label>
                                    <Select

                                        value={usuel}
                                        className="gx-mr-6 gx-mb-6"
                                        placeholder="Selectionner une valeur"

                                        onChange={this.handleChangeUsuel}
                                    >
                                        <Option value="1">Usuel</Option>
                                        <Option value="0">Non Usuel</Option>
                                    </Select>
                                </FormGroup>
            </Col>*/}
                        </Row>
                        

                    </Form>
                </Modal>

                <Modal
                    width={800}
                    visible={isModalVisible}
                    title="Détails Client"
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button  type="danger" onClick={this.handleCancel}>
                            Retour
                        </Button>,

                    ]}
                >
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="raisonSociale">Raison Sociale</Label>
                                    <Input disabled type="text" name="raisonSociale" id="raisonSociale" value={raisonSociale} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="ifu">IFU</Label>
                                    <Input disabled type="number" name="ifu" value={ifu} id="ifu" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        {/*<Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="numeroCarte">Numéro Carte</Label>
                                    <Input type="number" name="numeroCarte" id="numeroCarte" value={numeroCarte} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                </Row>*/}
                        <Row>
                            <Col md={6}>
                                <FormGroup >
                                    <Label for="gender">Sexe</Label>
                                    <Select disabled className="gx-mr-6 gx-mb-6" value={gender} onChange={this.handleChangeGender}>
                                        <Option value="M">Masculin</Option>
                                        <Option value="F">Feminin</Option>
                                    </Select>
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <Label for="dateNaissance">Date de Naissance</Label>
                                <FormGroup>

                                    <DatePicker disabled style={{ width: '100%' }} name="dateNaissance" id="dateNaissance" value={moment(dateNaissance, dateFormatList[0])} locale={locale} onChange={this.handleChangeDate} format={dateFormatList} />
                                    
                               </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="adresse">Adresse</Label>
                                    <Input disabled type="text" name="adresse" value={adresse} onChange={this.handleChange} id="adresse" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="profession">Profession</Label>
                                    <Input disabled type="text" name="profession" id="profession" value={profession} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>



                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="contact">Téléphone</Label>
                                    <Input disabled type="text" name="contact" id="contact" value={contact} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="contact2">Téléphone 2</Label>
                                    <Input disabled type="text" name="contact2" value={contact2} id="contact2" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>


                        <Row form>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input disabled type="text" name="email" value={email} id="email" onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="siteWeb">Site web</Label>
                                    <Input disabled type="text" name="siteWeb" id="siteWeb" value={siteWeb} onChange={this.handleChange} placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="typeClient">Type de Client</Label>
                                    <Select
                                    disabled
                                        value={typeClient}
                                        className="gx-mr-6 gx-mb-6"
                                        placeholder="Selectionner un Type"
                                        onChange={this.handleChangeTypeClient}
                                    >
                                        <Option value="CLIENT DETAILLANT">CLIENT DETAILLANT</Option>
                                        <Option value="CLIENT EN GROS">CLIENT EN GROS</Option>
                                        <Option value="CLIENT ORDINAIRE">CLIENT ORDINAIRE</Option>

                                    </Select>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <Label for="mentionClient">Mention Client</Label>
                                <FormGroup>

                                    <Radio.Group
                                    // options={optionsWithDisabled}
                                    // onChange={this.onChangeMention}
                                    //value={mentionClient}
                                    //optionType="button"
                                    //buttonStyle="solid"
                                    />
                                    <Radio.Group onChange={this.onChangeMention} disabled value={mentionClient} defaultValue={mentionClient} buttonStyle="solid">
                                        <Radio.Button value="">Aucune option</Radio.Button>
                                        <Radio.Button value="NOUVEAU CLIENT">Nouveau Client</Radio.Button>
                                        <Radio.Button value="ANCIEN CLIENT">Ancien Client</Radio.Button>
                                    </Radio.Group>
                                </FormGroup>
                            </Col>
                           {/* <Col md={6}>
                                <FormGroup>
                                    <Label for="usuel">Usuel</Label>
                                    <Select
                                        disabled
                                        value={usuel}
                                        className="gx-mr-6 gx-mb-6"
                                        placeholder="Selectionner une valeur"

                                        onChange={this.handleChangeUsuel}
                                    >
                                        <Option value="1">Usuel</Option>
                                        <Option value="0">Non Usuel</Option>
                                    </Select>
                                </FormGroup>
            </Col>*/}
                        </Row>
                        

                    </Form>
                </Modal>



            </div>
        )
    }
}


export default Clients;
